import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuthentication } from '@sbrc/hooks';

import FullPageLoader from '../FullPageLoader';

import PortraitBlocker from './PortraitBlocker';

interface PrivatePageProps {
  children: React.ReactNode;
}

/**
 * This page is only displayed when a user is authenticated.
 * If they're not logged in, then they're redirected to the login page.
 */
export default function PrivatePage({ children }: PrivatePageProps) {
  const { userID } = useAuthentication();
  const { asPath, replace } = useRouter();

  useEffect(() => {
    if (userID === null) {
      replace(`/login?redirectTo=${asPath}`);
    }
  }, [userID, replace, asPath]);

  if (userID === null) {
    return null;
  }

  if (userID === undefined) {
    return (
      <main>
        <FullPageLoader label="Authenticating user" />
      </main>
    );
  }

  return (
    <>
      <div>{children}</div>
      <PortraitBlocker />
    </>
  );
}
