import {
  Button,
  Icon,
  SettingsGroup,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import { CustomGripperTooltipConfiguration } from '../types/CustomGripperConfiguration';

import { EditTooltip } from './EditTooltip';

export function CustomGripperEquipmentManager({
  item,
  onUpdate,
  isFormDisabled,
  setChildContent,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'CustomGripper') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  return (
    <section>
      <h5 className="tw-heading-40 tw-pl-16 tw-justify-between">
        <span>Tooltips</span>
        <Button
          variant="Flat"
          size={32}
          className="tw-font-regular"
          disabled={isFormDisabled}
          onClick={() => {
            onUpdate({
              ...item,
              tooltips: [
                ...item.tooltips,
                CustomGripperTooltipConfiguration.parse({
                  name: `Tooltip ${item.tooltips.length + 1}`,
                }),
              ],
            });
          }}
        >
          <Icon kind="plus" />
          <span>Add tooltip</span>
        </Button>
      </h5>

      <SettingsGroup>
        {item.tooltips.map((tooltip, ix) => (
          <SettingsGroupNavigationItem
            key={tooltip.name}
            label={tooltip.name}
            onClick={() => {
              setChildContent(
                <EditTooltip
                  initialValue={tooltip}
                  otherTooltips={item.tooltips.filter((t) => t !== tooltip)}
                  isDisabled={isFormDisabled}
                  onBack={() => setChildContent(null)}
                  onUpdate={(newTooltip) => {
                    const tooltips = [...item.tooltips];
                    tooltips[ix] = newTooltip;
                    onUpdate({ ...item, tooltips });
                    setChildContent(null);
                  }}
                  onDelete={() => {
                    const tooltips = [...item.tooltips];
                    tooltips.splice(ix, 1);
                    onUpdate({ ...item, tooltips });
                    setChildContent(null);
                  }}
                />,
              );
            }}
          />
        ))}
      </SettingsGroup>
    </section>
  );
}
