import cx from 'classnames';

import { Icon, NavigationBar } from '@sb/design-system';

import styles from './WidgetView.module.css';

interface WidgetViewProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  /** @deprecated */
  headerButton?: React.ReactNode;
  /** @deprecated */
  headerIcon?: React.ReactElement;
  /** @deprecated */
  headerTitle?: string;
  onClose?: () => void;
}

const WidgetView = ({
  children,
  className,
  headerButton,
  headerIcon,
  headerTitle,
  onClose,
  ...rest
}: WidgetViewProps) => {
  // deprecated - header should be set by including a `NavigationBar` component in the children
  if (headerTitle) {
    return (
      <div
        {...rest}
        className={cx(
          className,
          'tw-surface-secondary',
          'tw-shadow-80',
          'tw-rounded-18',
          styles.containerWithHeader,
        )}
      >
        <NavigationBar
          contentLeft={headerIcon}
          contentRight={
            <div className={cx('tw-flex', 'tw-items-center', 'tw-gap-8')}>
              {headerButton}
              {onClose && (
                <Icon
                  kind="xmark"
                  data-testid="widget-panel-close-button"
                  className={cx('tw-text-label-secondary', 'tw-icon-20')}
                  onClick={onClose}
                />
              )}
            </div>
          }
        >
          {headerTitle}
        </NavigationBar>

        <div className={styles.content}>{children}</div>
      </div>
    );
  }

  return (
    <div
      {...rest}
      className={cx(
        className,
        'tw-surface-secondary',
        'tw-shadow-80',
        'tw-rounded-18',
        styles.content,
      )}
    >
      {children}
    </div>
  );
};

export default WidgetView;
