import { applyCompoundPose } from '@sb/geometry';
import type { Integrations } from '@sb/remote-control/types';
import { EditEquipment } from '@sbrc/components/equipment-manager/EditEquipment';

import {
  OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
  OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
} from '../constants';

import { QuickChangerItem } from './QuickChangerItem';

export function OnRobotDualQuickChangerEquipmentManager({
  item,
  otherEquipment,
  setChildContent,
  onUpdate,
  getEquipmentByKind,
  isFormDisabled,
  robot,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobotDualQuickChanger') {
    throw new Error(
      'Calling OnRobotDualQuickChanger method on non OnRobotDualQuickChanger item',
    );
  }

  const getChildEquipmentByKind: (
    childKind: 'primary' | 'secondary',
  ) => Integrations.GetEquipmentByKind = (childKind) => (deviceKind) => {
    const equipment = getEquipmentByKind(deviceKind);

    return {
      ...equipment,
      getDefaultItem: () => {
        const defaultItem = equipment.getDefaultItem();

        if ('tcpTransform' in defaultItem) {
          const basePose =
            childKind === 'primary'
              ? OR_DUAL_QUICK_CHANGER_PRIMARY_POSE
              : OR_DUAL_QUICK_CHANGER_SECONDARY_POSE;

          return {
            ...defaultItem,
            tcpTransform: applyCompoundPose(defaultItem.tcpTransform, basePose),
          };
        }

        return defaultItem;
      },
    };
  };

  const onClickChildEquipment = (child: 'primary' | 'secondary') => {
    const childItem = {
      id: child,
      config: item.grippers[child],
      isEnabled: true,
    };

    setChildContent(
      <EditEquipment
        key={child}
        equipmentItem={childItem}
        otherEquipment={otherEquipment}
        onBack={() => setChildContent(null)}
        onSave={({ config }) => {
          onUpdate({
            ...item,
            grippers: {
              ...item.grippers,
              [child]: config,
            },
          });

          setChildContent(null);

          return Promise.resolve();
        }}
        getEquipmentByKind={getChildEquipmentByKind(child)}
        isFormDisabled={isFormDisabled}
        robot={robot}
        isChildDevice
      />,
    );
  };

  return (
    <>
      <div className="tw-flex tw-flex-col">
        <h5 className="tw-heading-40 tw-pl-16">Primary gripper</h5>
        <QuickChangerItem
          item={item.grippers.primary}
          onClick={() => onClickChildEquipment('primary')}
          onChange={(primary) => {
            onUpdate({
              ...item,
              grippers: {
                ...item.grippers,
                primary,
              },
            });
          }}
          isDisabled={isFormDisabled}
          getEquipmentByKind={getChildEquipmentByKind('primary')}
        />
      </div>

      <div>
        <h5 className="tw-heading-40 tw-pl-16">Secondary gripper</h5>
        <QuickChangerItem
          item={item.grippers.secondary}
          onClick={() => onClickChildEquipment('secondary')}
          onChange={(secondary) =>
            onUpdate({
              ...item,
              grippers: {
                ...item.grippers,
                secondary,
              },
            })
          }
          isDisabled={isFormDisabled}
          getEquipmentByKind={getChildEquipmentByKind('secondary')}
        />
      </div>
    </>
  );
}
