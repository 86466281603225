import { Menu, MenuItem, RichSelect } from '@sb/design-system';
import type { DistanceUnit } from '@sb/remote-control/types';
import type { SelectProps } from '@sb/ui/components';
import { Select, SelectItem } from '@sb/ui/components';
import { useFeatureFlag } from '@sbrc/hooks';
import { DISTANCE_UNIT_INFOS, getDistanceUnitInfo } from '@sbrc/utils';

interface SelectDistanceUnitProps extends Omit<SelectProps, 'children'> {
  value: DistanceUnit;
  onSelect: (value: DistanceUnit) => void;
  isDisabled?: boolean;
  label?: string;
  useWithoutModal?: boolean;
}

export function SelectDistanceUnit({
  value,
  onSelect,
  isDisabled,
  label,
  // temporary fix: not to use in the Modal due to differ z-index.
  // after redesigning Modal - delete this flag and use RichSelect
  useWithoutModal,
  ...rest
}: SelectDistanceUnitProps) {
  const enableNewUISteps = useFeatureFlag('newUISteps');

  if (enableNewUISteps && useWithoutModal) {
    return (
      <div>
        <RichSelect
          className="tw-text-label-secondary"
          disabled={isDisabled}
          options={
            <Menu>
              {DISTANCE_UNIT_INFOS.map((info) => (
                <MenuItem
                  key={info.unit}
                  onClick={() => onSelect(info.unit)}
                  secondaryIconKind={
                    info.unit === value ? 'checkmark' : 'blank'
                  }
                >
                  {info.name}
                </MenuItem>
              ))}
            </Menu>
          }
        >
          {getDistanceUnitInfo(value).name}
        </RichSelect>
      </div>
    );
  }

  return (
    <Select activeLabel={getDistanceUnitInfo(value).name} {...rest}>
      {DISTANCE_UNIT_INFOS.map((info) => (
        <SelectItem
          key={info.unit}
          isSelected={info.unit === value}
          onClick={() => onSelect(info.unit)}
        >
          {info.name}
        </SelectItem>
      ))}
    </Select>
  );
}
