import { Icon } from '@sb/design-system';

export function EmptyState() {
  return (
    <div className="tw-flex tw-flex-col tw-py-60 tw-gap-12">
      <Icon
        kind="bell"
        className="tw-text-label-secondary tw-icon-42 tw-mx-auto"
      />
      <p className="tw-text-center">No notifications</p>
    </div>
  );
}
