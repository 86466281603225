import { HoldableButton } from '@sb/design-system';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { OnRobotScrewdriverCommand } from '../..';

import type { DualValueModeProps } from './types';

export default function RepositionShank({
  gripperControlState,
  isVizbot,
}: DualValueModeProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    isVizbot,
    shouldStopBeforeRun: false,
  });

  const { setConformedState, isShankPositionEqual } = gripperControlState;

  const command: OnRobotScrewdriverCommand = {
    ...gripperControlState.command,
    commandKind: 'SET_SHANK_POSITION',
  };

  const onHoldRepositionShank = () => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command,
        });

        setConformedState('outdated');

        return actuateGripper;
      },

      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
    });
  };

  return (
    <HoldableButton
      variant="Filled"
      className="tw-rounded-6"
      onHold={onHoldRepositionShank}
      onRelease={stopGuidedMode}
      disabled={isShankPositionEqual}
      data-testid="gripper-widget-apply-changes-button"
    >
      Hold to adjust Z-axis
    </HoldableButton>
  );
}
