import type { GripperState } from '@sb/integrations/gripper-general';
import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { OnRobot2FG7State } from '@sb/integrations/OnRobot2FG7';
import type { Integrations } from '@sb/remote-control/types';
import { useRobotPayloadState } from '@sbrc/hooks';

import type {
  CompatibleCommand,
  OnRobotDualQuickChangerCommand,
  OnRobotDualQuickChangerState,
} from '..';

import { DualOnRobot2FG7WidgetInner } from './DualOnRobot2FG7WidgetInner';

function get2FG7State(gripperState?: GripperState): OnRobot2FG7State | null {
  return gripperState?.kind === 'OnRobot2FG7' ? gripperState : null;
}

interface DualOnRobot2FG7WidgetProps extends Integrations.WidgetProps {
  additionalControls?: React.ReactNode;
  routineRunnerDualChangerState: OnRobotDualQuickChangerState;
  otherRobotDualChangerState: OnRobotDualQuickChangerState | null;
  dualChangerCommand: OnRobotDualQuickChangerCommand;
  changeActiveGripperCommand: (action: CompatibleCommand) => void;
}

export function DualOnRobot2FG7Widget({
  isVizbot,
  onCommandChange,
  robot,
  additionalControls,
  routineRunnerDualChangerState,
  otherRobotDualChangerState,
  dualChangerCommand,
  changeActiveGripperCommand,
  getEquipmentByKind,
  implementation,
}: DualOnRobot2FG7WidgetProps) {
  const routineRunnerGripperState = get2FG7State(
    routineRunnerDualChangerState[dualChangerCommand.active],
  );

  const otherRobotGripperState = get2FG7State(
    otherRobotDualChangerState?.[dualChangerCommand.active],
  );

  const routineRunnerPayload = useRobotPayloadState({ isVizbot });

  if (
    !routineRunnerGripperState ||
    routineRunnerPayload === null ||
    !routineRunnerGripperState.isConnected
  ) {
    return (
      <GripperErrorWidget
        implementation={implementation}
        additionalControls={additionalControls}
        message="The gripper does not appear to be connected. Please ensure a 2FG7 gripper is properly mounted and connected."
      />
    );
  }

  return (
    <DualOnRobot2FG7WidgetInner
      {...{
        routineRunnerPayload,
        otherRobotGripperState,
        routineRunnerGripperState,
        additionalControls,
        robot,
        isVizbot,
        onCommandChange,
        dualChangerCommand,
        changeActiveGripperCommand,
        getEquipmentByKind,
      }}
    />
  );
}
