import cx from 'classnames';

import { SettingsGroup, SettingsGroupItem } from '@sb/design-system';
import type { softwareUpdate } from '@sbrc/hooks';
import { useFirmwareUpdateStatus } from '@sbrc/hooks';

import { FirmwareStatusIcon } from './FirmwareStatusIcon';

type Props = {
  buildData: softwareUpdate.SoftwareBuildData;
};

export function FirmwareJointStatus(props: Props) {
  const { buildData } = props;

  const { jointUpdateStatus } = useFirmwareUpdateStatus(buildData);

  return (
    <div>
      <SettingsGroup className="tw-mb-18 tw-overflow-hidden">
        {jointUpdateStatus.map(({ jointNumber, jointName, status }) => (
          <SettingsGroupItem className={cx('tw-py-8')} key={jointNumber}>
            <div
              className={cx(
                'tw-flex-1',
                'tw-flex',
                'tw-items-center',
                'tw-gap-8',
              )}
            >
              {jointName}
            </div>

            <FirmwareStatusIcon status={status} />
          </SettingsGroupItem>
        ))}
      </SettingsGroup>
    </div>
  );
}
