import cx from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useId, useState } from 'react';

import {
  Menu,
  MenuItem,
  NavigationBar,
  NavigationBarBackButton,
  NavigationBarButton,
  NavigationBarMenuButton,
  SettingsGroupSwitchItem,
  TextInput,
  useConfirmModal,
} from '@sb/design-system';
import { canAddIntegration } from '@sb/integrations/frontend/util';
import type { Equipment, Integrations, Robot } from '@sb/remote-control/types';

import { NoEquipmentEditorForm } from './NoEquipmentEditorForm';

type EditEquipmentProps = {
  equipmentItem: Equipment.EditableFields;
  otherEquipment: Equipment.ConvertedResponse[];
  robot: Robot.ConvertedResponse;
  onSave: (settings: Equipment.EditableFields) => Promise<void>;
  onDelete?: () => Promise<void>;
  onBack: () => void;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
  showEnabledSwitch?: boolean;
  isFormDisabled: boolean;
  isChildDevice?: boolean;
};

export function EditEquipment({
  equipmentItem,
  otherEquipment,
  robot,
  onSave,
  onDelete,
  onBack,
  getEquipmentByKind,
  showEnabledSwitch,
  isFormDisabled,
  isChildDevice,
}: EditEquipmentProps) {
  const [settingsState = equipmentItem, setSettingsState] = useState<
    Equipment.EditableFields | undefined
  >(equipmentItem);

  const [childContent, setChildContent] = useState<React.ReactElement | null>(
    null,
  );

  const isNew = !settingsState.id;
  const isUnchanged = isEqual(settingsState.config, equipmentItem.config);

  const [unsavedChangesAlert, openUnsavedChangesAlert] = useConfirmModal({
    body: 'Are you sure you want to leave without saving your changes?',
    cancelLabel: 'Keep editing',
    confirmLabel: 'Discard changes',
    onConfirm: onBack,
    autoConfirm: isUnchanged,
  });

  const [deleteAlert, openDeleteAlert] = useConfirmModal({
    body: 'Are you sure you want delete this equipment configuration?',
    onConfirm: onDelete,
  });

  /**
   * Array of fields which are currently set as invalid.
   * If the array is not empty then the form cannot be saved
   */
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const setIsFieldValid = (field: string) => (isValid: boolean) => {
    if (isValid && invalidFields.includes(field)) {
      setInvalidFields(invalidFields.filter((f) => f !== field));
    } else if (!isValid && !invalidFields.includes(field)) {
      setInvalidFields([...invalidFields, field]);
    }
  };

  useEffect(() => {
    setSettingsState(undefined);
    setChildContent(null);
  }, [equipmentItem.id]);

  const inputID = useId();

  const { EquipmentManagerEditForm = NoEquipmentEditorForm, getDisplayName } =
    getEquipmentByKind(settingsState.config.kind);

  if (childContent) {
    return childContent;
  }

  const isEnableable =
    showEnabledSwitch &&
    canAddIntegration(
      settingsState.config.kind,
      otherEquipment,
      getEquipmentByKind,
    );

  return (
    <>
      <NavigationBar
        className="tw-mb-8"
        contentLeft={
          <NavigationBarBackButton
            onClick={openUnsavedChangesAlert}
            data-testid="edit-equipment-back"
          />
        }
        contentRight={
          <>
            {onDelete && (
              <NavigationBarMenuButton
                data-testid="edit-equipment-more"
                content={
                  <Menu>
                    <MenuItem
                      className="tw-text-red"
                      iconKind="trash"
                      onClick={openDeleteAlert}
                      data-testid="edit-equipment-delete"
                      disabled={isFormDisabled || isNew}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                }
              />
            )}
            <NavigationBarButton
              onClick={async () => {
                await onSave(settingsState);
                onBack();
              }}
              disabled={
                isFormDisabled ||
                invalidFields.length > 0 ||
                (!isNew && isUnchanged)
              }
            >
              Save
            </NavigationBarButton>
          </>
        }
      >
        {getDisplayName()}
      </NavigationBar>

      <div
        className={cx(
          'tw-flex',
          'tw-flex-col',
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-pb-24',
          'tw-px-24',
          'tw-gap-32',
        )}
      >
        {showEnabledSwitch && (
          <SettingsGroupSwitchItem
            isSeparated
            label="Enabled"
            checked={settingsState.isEnabled}
            disabled={
              isFormDisabled ||
              invalidFields.length > 0 ||
              (!settingsState.isEnabled && !isEnableable)
            }
            onChange={async (e) => {
              if (e.target.checked && !isEnableable) {
                return;
              }

              const newSettingsState = {
                ...settingsState,
                isEnabled: e.target.checked,
              };

              await onSave(newSettingsState);
              setSettingsState(newSettingsState);
            }}
          />
        )}

        <div className="tw-flex tw-flex-col">
          <label htmlFor={inputID} className="tw-heading-40 tw-pl-16">
            Name
          </label>

          <TextInput
            id={inputID}
            size={44}
            className="tw-rounded-10"
            value={settingsState.config.name}
            placeholder="None"
            onChange={(value) => {
              setSettingsState({
                ...settingsState,
                config: { ...settingsState.config, name: value },
              });

              setIsFieldValid('name')(value !== '');
            }}
            data-testid="name-input"
            disabled={isFormDisabled}
          />
        </div>

        <EquipmentManagerEditForm
          item={settingsState.config}
          otherEquipment={otherEquipment}
          robot={robot}
          onUpdate={(value) =>
            setSettingsState({ ...settingsState, config: value })
          }
          getEquipmentByKind={getEquipmentByKind}
          setChildContent={setChildContent}
          isFormDisabled={isFormDisabled}
          setIsFieldValid={setIsFieldValid}
          isChildDevice={isChildDevice}
        />
      </div>

      {unsavedChangesAlert}
      {deleteAlert}
    </>
  );
}
