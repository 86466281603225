import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Button, Icon } from '@sb/design-system';
import { useFeatureFlag } from '@sbrc/hooks';
import { executeHostCommand } from '@sbrc/services/feathers-client';

import {
  CAMERA_DISCONNECTED_MESSAGE,
  getCameraStream,
} from './getCameraStream';

async function restartCamera() {
  await executeHostCommand({
    command: 'restartCamera',
  });
}

interface CameraStreamProps extends React.HTMLAttributes<HTMLCanvasElement> {
  /* rotation in quarter-turns clockwise */
  rotation?: number;
}

type StreamState = 'loading' | 'loaded' | 'error';

export function CameraStream({
  children,
  className,
  rotation = 0,
  ...rest
}: CameraStreamProps) {
  const [streamState, setStreamState] = useState<StreamState>('loading');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [cameraIsRestarting, setCameraIsRestarting] = useState(false);

  const showCameraRestartButton = useFeatureFlag('cameraRestartButton');

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let rafID = 0;

    const runRAF = () => {
      rafID = requestAnimationFrame(() => {
        const { imageBitmap, error } = getCameraStream();

        if (error) {
          setStreamState('error');
          setErrorMessage(error);
        } else if (imageBitmap) {
          setStreamState('loaded');
          setErrorMessage(null);
        }

        const canvasEl = canvasRef.current;
        const canvasCtx = canvasEl?.getContext('2d');

        if (imageBitmap && canvasEl && canvasCtx) {
          let { width, height } = imageBitmap;

          if (rotation % 2) {
            [height, width] = [width, height];
          }

          if (width !== canvasEl.width) {
            canvasEl.width = width;
          }

          if (height !== canvasEl.height) {
            canvasEl.height = height;
          }

          if (rotation) {
            canvasCtx.save();
            canvasCtx.translate(width / 2, height / 2);
            canvasCtx.rotate((rotation * Math.PI) / 2);

            canvasCtx.drawImage(
              imageBitmap,
              -imageBitmap.width / 2,
              -imageBitmap.height / 2,
              imageBitmap.width,
              imageBitmap.height,
            );

            canvasCtx.restore();
          } else {
            canvasCtx.drawImage(imageBitmap, 0, 0);
          }

          const aspectRatio = `${width} / ${height}`;

          if (
            canvasEl.parentElement &&
            canvasEl.parentElement.style.aspectRatio !== aspectRatio
          ) {
            canvasEl.parentElement.style.aspectRatio = aspectRatio;
          }
        }

        // loop
        runRAF();
      });
    };

    runRAF();

    return () => cancelAnimationFrame(rafID);
  }, [rotation]);

  if (streamState === 'loaded') {
    return (
      <div
        className={cx(
          'tw-relative',
          'tw-max-w-full',
          'tw-max-h-full',
          'tw-touch-none',
          className,
        )}
      >
        <div
          /* make the container as large as possible within the constraints of its parent */
          className={cx('tw-h-0', 'tw-w-screen')}
        />

        <canvas
          ref={canvasRef}
          className={cx('tw-h-full', 'tw-w-full')}
          {...rest}
        />

        {children}
      </div>
    );
  }

  if (streamState === 'error') {
    return (
      <div
        className={cx(
          'tw-flex',
          'tw-flex-col',
          'tw-p-20',
          'tw-items-center',
          'tw-gap-16',
          'tw-max-w-512',
          className,
        )}
      >
        <Icon
          kind="exclamationTriangle"
          className={cx('tw-icon-42', 'tw-text-yellow-50')}
        />
        <div className={cx('tw-flex', 'tw-flex-col', 'tw-items-center')}>
          <span className={cx('tw-text-center', 'tw-text-bold')}>
            <strong>Error loading camera stream</strong>
          </span>
          <span className={cx('tw-text-center')}>{errorMessage}</span>
        </div>
        {showCameraRestartButton &&
          errorMessage === CAMERA_DISCONNECTED_MESSAGE && (
            <div
              className={cx(
                'tw-flex',
                'tw-flex-col',
                'tw-items-center',
                'tw-gap-16',
              )}
            >
              <label className={cx('tw-flex', 'tw-items-center', 'tw-gap-16')}>
                <div
                  className={cx(
                    'tw-relative',
                    'tw-flex-none',
                    'tw-w-24',
                    'tw-h-24',
                    'tw-bg-fill-primary',
                    'tw-rounded-6',
                    'tw-border',
                    isChecked
                      ? 'tw-border-orange'
                      : 'tw-border-label-quaternary',
                    'tw-text-orange',
                    'tw-flex',
                    'tw-items-center',
                    'tw-justify-center',
                  )}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className={cx(
                      'tw-appearance-none',
                      'tw-opacity-0',
                      'tw-absolute',
                    )}
                    disabled={cameraIsRestarting}
                  />
                  {isChecked && (
                    <Icon kind="checkmark" className="tw-icon-22" />
                  )}
                </div>
                <span className="tw-text-15">
                  I acknowledge the robot computer will shut down for 30 seconds
                  when attempting to reconnect the camera
                </span>
              </label>

              <Button
                variant="Filled"
                onClick={async () => {
                  setCameraIsRestarting(true);
                  await restartCamera();
                  setCameraIsRestarting(false);
                }}
                className="tw-rounded-6"
                disabled={cameraIsRestarting || !isChecked}
              >
                Reconnect camera
              </Button>
            </div>
          )}
      </div>
    );
  }

  return (
    <div
      className={cx(
        'tw-flex',
        'tw-p-20',
        'tw-items-center',
        'tw-gap-8',
        className,
      )}
    >
      <Icon kind="equipmentCamera" />
      <span>Loading camera stream...</span>
    </div>
  );
}
