import { useEffect } from 'react';

import { NavigationBar } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { OnRobot2FG7State } from '../State';

import { GripperControlDualValueMode } from './dual-value-mode/GripperControlDualValueMode';
import { GripperControlDualValueModeMoreMenu } from './dual-value-mode/GripperControlDualValueModeMoreMenu';
import { useGripperControlState } from './gripper-control-state';

interface OnRobot2FG7WidgetInnerProps {
  routineRunnerPayload: number;
  routineRunnerGripperState: OnRobot2FG7State;
  otherRobotGripperState: OnRobot2FG7State | null;
  args: Integrations.WidgetProps;
}

export function OnRobot2FG7WidgetInner({
  routineRunnerPayload,
  routineRunnerGripperState,
  otherRobotGripperState,
  args,
}: OnRobot2FG7WidgetInnerProps) {
  const {
    isVizbot,
    onCommandChange = () => {},
    implementation: onRobot2FG7Implementation,
  } = args;

  const gripperControlState = useGripperControlState({
    isVizbot,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const { command } = gripperControlState;

  useEffect(() => {
    if (onCommandChange) {
      onCommandChange(command);
    }
  }, [command, onCommandChange]);

  return (
    <WidgetView data-testid="onrobot-2fg7-control-widget">
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <GripperControlDualValueModeMoreMenu
            gripperControlState={gripperControlState}
            isVizbot={isVizbot}
            otherRobotGripperState={otherRobotGripperState}
          />
        }
      >
        {onRobot2FG7Implementation.getDisplayName()}
      </NavigationBar>

      <GripperControlDualValueMode
        gripperControlState={gripperControlState}
        isVizbot={isVizbot}
      />
    </WidgetView>
  );
}
