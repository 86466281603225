import { useId } from 'react';

import {
  NumberInput,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSelectItem,
} from '@sb/design-system';

import type { OnRobot2FG7FingerConfiguration } from '../types/Configuration';

import { MountingPositionIcon } from './MountingPositionIcon';

interface FingerGeometryProps {
  value: OnRobot2FG7FingerConfiguration;
  onChange: (fingerConfiguration: OnRobot2FG7FingerConfiguration) => void;
  isDisabled: boolean;
}

export function FingerGeometry({
  value,
  onChange,
  isDisabled,
}: FingerGeometryProps) {
  const inputID = useId();

  return (
    <fieldset className="tw-flex tw-flex-col" disabled={isDisabled}>
      <legend className="tw-heading-40 tw-pl-16">Finger geometry</legend>

      <SettingsGroup>
        <SettingsGroupSelectItem
          label="Mounting position"
          secondaryLabel={
            <MountingPositionIcon mountingPositon={value.mountingPosition} />
          }
          value={value.mountingPosition}
          onChange={(e) => {
            switch (e.target.value) {
              case 'inward':
                onChange({
                  ...value,
                  mountingPosition: 'inward',
                });

                break;
              default:
                onChange({
                  ...value,
                  mountingPosition: 'outward',
                });
            }
          }}
        >
          <option value="inward">Inward</option>
          <option value="outward">Outward</option>
        </SettingsGroupSelectItem>

        <SettingsGroupItem>
          <label htmlFor={inputID} className="tw-flex-1">
            Fingertip offset
          </label>
          <NumberInput
            id={inputID}
            size={32}
            variant="Gray"
            className="tw-w-128"
            alignment="Center"
            value={value.offsetInMeters * 1000}
            decimalPlaces={2}
            onChange={(newOffset: number) => {
              onChange({
                ...value,
                offsetInMeters: newOffset / 1000,
              });
            }}
          />
          <span className="tw-w-52 tw-text-right">mm</span>
        </SettingsGroupItem>
      </SettingsGroup>
    </fieldset>
  );
}
