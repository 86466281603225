import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';

import type { OnRobot3FG15State } from '../../State';
import { areOR3FG15GripperPositionsEqual } from '../../utils';

import type { DualValueModeProps } from './types';

interface GripperControlDualValueModeMoreMenu extends DualValueModeProps {
  otherRobotGripperState: OnRobot3FG15State | null;
}

export function GripperControlDualValueModeMoreMenu({
  isVizbot,
  gripperControlState,
  otherRobotGripperState,
}: GripperControlDualValueModeMoreMenu) {
  const {
    canApplyGripperChanges,
    setTargetDiameter,
    setGripKind,
    resetAll,
    command,
  } = gripperControlState;

  const areGripperPositionsEqual = areOR3FG15GripperPositionsEqual(
    otherRobotGripperState,
    command.targetDiameter,
    command,
  );

  return (
    <NavigationBarMenuButton
      data-testid="3f15-control-more-menu"
      placement="bottom-start"
      content={
        <Menu>
          <MenuItem
            data-testid="3fg15-control-import-position"
            onClick={() => {
              if (otherRobotGripperState) {
                setGripKind(otherRobotGripperState.gripKind);
                setTargetDiameter(otherRobotGripperState.diameterMeters);
              }
            }}
            disabled={areGripperPositionsEqual || !otherRobotGripperState}
            iconKind="arrowDownSquare"
          >
            Import {isVizbot ? 'live robot' : 'visualizer'} position
          </MenuItem>

          <MenuItem
            data-testid="3fg15-control-reset-all"
            onClick={() => resetAll()}
            disabled={!canApplyGripperChanges}
            iconKind="arrowUturnLeft"
          >
            Reset all
          </MenuItem>
        </Menu>
      }
    />
  );
}
