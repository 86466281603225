import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Icon } from '@sb/design-system';
import { useAuthentication } from '@sbrc/hooks';
import { onGetNotificationsCount } from '@sbrc/services';

import { NotificationsModal } from './notifications-modal/NotificationsModal';

export function NotificationsHeaderButton() {
  const { userID } = useAuthentication();
  const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clicks, setRawClicks] = useState<number[]>([]);

  const onBellClick = useCallback(() => {
    setIsModalOpen(true);

    // Secret key combo to move to control-panel
    // Click bell 5 times (3 to open, 2 to close)
    // and the page will move to the control panel
    const newClicks = [
      ...clicks.filter((click) => click > Date.now() - 3_000),
      Date.now(),
    ];

    if (newClicks.length >= 3) {
      window.location.href = '/control-panel';
    }

    setRawClicks(newClicks);
  }, [clicks]);

  useEffect(() => {
    if (!userID) {
      return undefined;
    }

    return onGetNotificationsCount(userID, 'new', setCount);
  }, [userID]);

  return (
    <div className="tw-relative">
      <Icon
        kind="bell"
        role="button"
        aria-label="Notifications Menu"
        onClick={() => onBellClick()}
        data-testid="notifications-button"
        className="tw-icon-28 tw-text-label-secondary"
      />
      {count > 0 && (
        <div
          className={cx(
            'tw-absolute',
            'tw-top-[-5px]',
            'tw-right-[-5px]',
            'tw-rounded-full',
            'tw-surface-red',
            'tw-pointer-events-none',
            'tw-border-[2px]',
            'tw-border-[--surface-primary-elevated]',
            'tw-text-13',
            'tw-leading-none',
            'tw-flex',
            'tw-justify-center',
            'tw-items-center',
            'tw-h-20',
            'tw-min-w-[20px]',
            'tw-px-2',
          )}
          role="status"
          aria-label="Notifications"
        >
          {count > 19 ? '+' : count}
        </div>
      )}

      {isModalOpen && (
        <NotificationsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}
