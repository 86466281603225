/**
 * !!!!!!!! TODO shared logic with CreateWithCameraModal
 */

import { useEffect, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import * as log from '@sb/log';
import type { Point2D } from '@sb/routine-runner';
import { wait } from '@sb/utilities';
import { DEFAULT_WRIST_CAMERA } from '@sbrc/components/camera';
import { useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

const CHESSBOARD_DEFN = {
  cols: 7,
  rows: 5,
  // actually specified in meters, despite the name
  squareSizeMM: 0.03,
};

interface UseVisionChessboardCornersArgs {
  isOpen: boolean;
  onCapture: (transform: CartesianPose) => void;
}

export function useVisionChessboardCorners({
  isOpen,
  onCapture,
}: UseVisionChessboardCornersArgs) {
  const routineRunnerHandle = useRoutineRunnerHandle({});
  const { setToast } = useToast();

  const [chessboardCorners, setChessboardCorners] = useState<Point2D[] | null>(
    null,
  );

  useEffect(() => {
    if (!isOpen) {
      setChessboardCorners(null);

      return undefined;
    }

    let cancelled = false;

    const getChessboardCorners = async () => {
      while (!cancelled) {
        try {
          const cornersResult = await routineRunnerHandle.runVisionMethod({
            method: 'getChessboardCorners',
            camera: DEFAULT_WRIST_CAMERA,
            ...CHESSBOARD_DEFN,
          });

          if (cornersResult.method === 'getChessboardCorners') {
            setChessboardCorners(cornersResult.results);
          }
        } catch (error) {
          log.error(
            'useVisionChessboardCorners.getChessboardCorners.error',
            'Get chessboard corners failed',
            { error },
          );

          setChessboardCorners(null);
        }

        await wait(1000);
      }
    };

    getChessboardCorners();

    return () => {
      cancelled = true;
    };
  }, [routineRunnerHandle, isOpen]);

  const handleCapture = async () => {
    try {
      const transformResult = await routineRunnerHandle.runVisionMethod({
        method: 'getCameraChessboardTransform',
        camera: DEFAULT_WRIST_CAMERA,
        ...CHESSBOARD_DEFN,
      });

      if (transformResult.method === 'getCameraChessboardTransform') {
        onCapture(transformResult.results);
      }
    } catch (error) {
      log.error(
        'useVisionChessboardCorners.handleCapture.error',
        'getCameraChessboardTransform failed',
        { error },
      );

      setToast({
        kind: 'error',
        message: 'Failed to capture calibration board.',
      });
    }
  };

  return {
    chessboardCorners,
    handleCapture,
  };
}
