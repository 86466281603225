import type { Integrations } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';
import { noGripperImplementation } from '../NoGripper/frontend';
import { onRobot2FG7Implementation } from '../OnRobot2FG7/frontend';
import { onRobot3FG15Implementation } from '../OnRobot3FG15/frontend';

import { OR_DUAL_QUCK_CHANGER_SHORTNAME } from './constants';
import { OnRobotDualQuickChangerEquipmentManager } from './equipment-manager/OnRobotDualQuickChangerEquipmentManager';
import { getDualGripperVariables } from './getDualGripperVariables';
import { OnRobotDualQuickChangerConfiguration } from './types/Configuration';
import { OnRobotDualQuickChangerWidget } from './widget/OnRobotDualQuickChangerWidget';

export const onRobotDualQuickChangerImplementation: Integrations.IntegrationFrontend<'OnRobotDualQuickChanger'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'equipmentDualChanger',
    getSupportedSteps: () => ['ActuateGripper'],
    getDisplayName: () => {
      return 'OnRobot Dual Quick Changer';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => OR_DUAL_QUCK_CHANGER_SHORTNAME,
    getIntegrationVariables: (
      config: Integrations.IntegrationVariablesProps,
    ) => {
      if (config.item.kind !== 'OnRobotDualQuickChanger') return [];

      return getDualGripperVariables(
        config.item.grippers.primary.kind,
        config.item.grippers.secondary.kind,
      );
    },
    getDefaultItem: () =>
      OnRobotDualQuickChangerConfiguration.parse({
        kind: 'OnRobotDualQuickChanger',
        grippers: {
          primary: noGripperImplementation.getDefaultItem(),
          secondary: noGripperImplementation.getDefaultItem(),
        },
      }),
    Widget: OnRobotDualQuickChangerWidget,
    EquipmentManagerEditForm: OnRobotDualQuickChangerEquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobotDualQuickChanger') {
        return undefined;
      }

      if (!state.primary && !state.secondary) {
        return 'disconnected';
      }

      let primaryStatus;
      let secondaryStatus;

      if (state.primary) {
        primaryStatus =
          onRobot2FG7Implementation.getSummaryStatus?.(state.primary) ??
          onRobot3FG15Implementation.getSummaryStatus?.(state.primary);
      }

      if (state.secondary) {
        secondaryStatus =
          onRobot2FG7Implementation.getSummaryStatus?.(state.secondary) ??
          onRobot3FG15Implementation.getSummaryStatus?.(state.secondary);
      }

      if (
        (!primaryStatus && !secondaryStatus) ||
        (primaryStatus === 'disconnected' && secondaryStatus === 'disconnected')
      ) {
        return 'disconnected';
      }

      if (primaryStatus?.kind === 'error') {
        return primaryStatus;
      }

      if (secondaryStatus?.kind === 'error') {
        return secondaryStatus;
      }

      if (primaryStatus === 'busy' || secondaryStatus === 'busy') {
        return 'busy';
      }

      return 'idle';
    },
    getTCPOffsetOptions: (config) => {
      const tcpOffsetOptions: string[] = [];

      if (config.kind === 'OnRobotDualQuickChanger') {
        if (config.grippers.primary.kind !== 'NoGripper') {
          tcpOffsetOptions.push(`Primary: ${config.grippers.primary.name}`);
        }

        if (config.grippers.secondary.kind !== 'NoGripper') {
          tcpOffsetOptions.push(`Secondary: ${config.grippers.secondary.name}`);
        }
      }

      return tcpOffsetOptions;
    },
  };
