import { useState } from 'react';

import {
  Icon,
  Menu,
  MenuItem,
  NumberInput,
  Popper,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSelectItem,
} from '@sb/design-system';

import { OR_3FG15_FINGER_POSITION_DEFAULT } from '../constants';
import type { OnRobot3FG15FingerConfiguration } from '../types/Configuration';

interface FingerGeometryProps {
  value: OnRobot3FG15FingerConfiguration;
  onChange: (value: OnRobot3FG15FingerConfiguration) => void;
  isDisabled: boolean;
}

export function FingerGeometry({
  value,
  onChange,
  isDisabled,
}: FingerGeometryProps) {
  const [isOffsetPresetDropdownOpen, setIsOffsetPresetDropdownOpen] =
    useState(false);

  const updateOffset = (offset: number) => {
    onChange({
      ...value,
      offsetInMeters: offset / 1000,
    });
  };

  return (
    <fieldset className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Finger geometry</h5>
      <SettingsGroup>
        <SettingsGroupSelectItem
          label="Mounting position"
          value={value.mountingPosition}
          disabled={isDisabled}
          onChange={(e) => {
            const getMountingPosition = () => {
              switch (e.target.value) {
                case '1':
                  return 1;
                case '2':
                  return 2;
                case '3':
                  return 3;
                default:
                  return OR_3FG15_FINGER_POSITION_DEFAULT;
              }
            };

            onChange({
              ...value,
              mountingPosition: getMountingPosition(),
            });
          }}
        >
          <option value={1}>Position 1</option>
          <option value={2}>Position 2</option>
          <option value={3}>Position 3</option>
        </SettingsGroupSelectItem>

        <SettingsGroupItem>
          <span className="tw-flex-1">Finger length</span>
          <NumberInput
            className="tw-w-128"
            size={32}
            variant="Gray"
            alignment="Center"
            disabled={isDisabled}
            min={1}
            max={999}
            decimalPlaces={2}
            value={value.lengthInMeters * 1000}
            onChange={(length) =>
              onChange({
                ...value,
                lengthInMeters: length / 1000,
              })
            }
          />
          <span className="tw-w-52 tw-text-label-secondary tw-text-right">
            mm
          </span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1">Fingertip offset</span>
          <Popper
            isOpen={isOffsetPresetDropdownOpen}
            onClose={() => setIsOffsetPresetDropdownOpen(false)}
            onClick={() =>
              setIsOffsetPresetDropdownOpen(!isOffsetPresetDropdownOpen)
            }
            content={
              <Menu>
                <MenuItem onClick={() => updateOffset(10)}>10mm steel</MenuItem>
                <MenuItem onClick={() => updateOffset(13)}>13mm steel</MenuItem>
                <MenuItem onClick={() => updateOffset(13.5)}>
                  13.5mm silicon
                </MenuItem>
                <MenuItem onClick={() => updateOffset(16.5)}>
                  16.5mm silicon
                </MenuItem>
              </Menu>
            }
          >
            <Icon kind="ellipsisCircle" className="tw-text-label-secondary" />
          </Popper>

          <NumberInput
            className="tw-w-128"
            size={32}
            variant="Gray"
            alignment="Center"
            disabled={isDisabled}
            min={1}
            max={999}
            decimalPlaces={2}
            value={value.offsetInMeters * 1000}
            onChange={updateOffset}
          />
          <span className="tw-w-52 tw-text-label-secondary tw-text-right">
            mm
          </span>
        </SettingsGroupItem>
      </SettingsGroup>
    </fieldset>
  );
}
