import { isEqual } from 'lodash';
import { useState } from 'react';

import { Button, Icon } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import { EulerPoseSettingsGroup } from '@sbrc/components/euler-pose/EulerPoseSettingsGroup';
import { useFeatureFlag } from '@sbrc/hooks';
import { convertEulerPose } from '@sbrc/utils';

interface ConfigureTCPTransformProps {
  value: CartesianPose;
  onChange: (value: CartesianPose) => void;
  defaultValue?: CartesianPose;
  isDisabled?: boolean;
}

export function ConfigureTCPTransform({
  value,
  onChange,
  defaultValue,
  isDisabled,
}: ConfigureTCPTransformProps) {
  const enableTCPTransform = useFeatureFlag('tcpTransform');

  const [eulerTCPTransform, setEulerTCPTransform] = useState(() =>
    convertEulerPose.fromCartesian(value),
  );

  if (!enableTCPTransform) {
    return null;
  }

  return (
    <fieldset className="tw-flex tw-flex-col" disabled={isDisabled}>
      <h5 className="tw-heading-40 tw-pl-16 tw-justify-between">
        <span>TCP offset and rotation</span>
        {defaultValue && (
          <Button
            variant="Flat"
            size={32}
            className="tw-font-regular"
            disabled={isDisabled || isEqual(defaultValue, value)}
            onClick={() => {
              onChange(defaultValue);

              setEulerTCPTransform(
                convertEulerPose.fromCartesian(defaultValue),
              );
            }}
          >
            <Icon kind="arrowUturnLeft" />
            <span>Reset</span>
          </Button>
        )}
      </h5>

      <EulerPoseSettingsGroup
        value={eulerTCPTransform}
        onChange={(newValue) => {
          setEulerTCPTransform(newValue);
          onChange(convertEulerPose.toCartesian(newValue));
        }}
      />
    </fieldset>
  );
}
