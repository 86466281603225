import { clamp } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { isApproximatelyEqual } from '@sb/utilities';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';

import type { CommandKind, OnRobotScrewdriverCommand } from '../Command';
import {
  COMMAND_FORCE_N_DEFAULT,
  COMMAND_KIND_DEFAULT,
  COMMAND_SCREW_LENGTH_MM_DEFAULT,
  COMMAND_SHANK_POSITION_MM_DEFAULT,
  COMMAND_TORQUE_NM_DEFAULT,
  FORCE_RANGE,
  getCommandDefault,
  SCREW_LENGTH_RANGE,
  SHANK_POSITION_RANGE,
  TORQUE_RANGE,
} from '../constants';
import type { OnRobotScrewdriverState } from '../State';
import type { GripperConformState } from '../types';

interface UseGripperControlStateArguments {
  isVizbot: boolean;
  routineRunnerGripperState: OnRobotScrewdriverState;
  defaultCommand?: Partial<OnRobotScrewdriverCommand>;
}

interface GripperControlStateOutput {
  canApplyGripperChanges: boolean;
  changeCommandKind: (action: React.SetStateAction<CommandKind>) => void;
  changeTargetForce: (action: React.SetStateAction<number>) => void;
  changeScrewLength: (action: React.SetStateAction<number>) => void;
  changeTargetTorque: (
    action: React.SetStateAction<number> | undefined,
  ) => void;
  changeShankPosition: (
    action: React.SetStateAction<number> | undefined,
  ) => void;
  command: OnRobotScrewdriverCommand;
  conformGripperControlStateToActualState: (
    state: OnRobotScrewdriverState,
  ) => void;
  actual: {
    routineRunnerTorque: number;
    routineRunnerForce: number;
    routineRunnerShankPosition: number;
  };
  target: {
    targetForce: number;
    targetScrewLength: number;
    targetTorque: number | undefined;
    targetShankPosition: number | undefined;
  };
  setConformedState: (formState: GripperConformState) => void;
  isShankPositionEqual: boolean;
  isDisabled: boolean;
  isConnected: boolean;
}

export function useGripperControlState({
  isVizbot,
  routineRunnerGripperState,
  defaultCommand,
}: UseGripperControlStateArguments): GripperControlStateOutput {
  const [conformedState, setConformedState] =
    useState<GripperConformState>('outdated');

  const [command, setCommand] = useState<OnRobotScrewdriverCommand>(
    getCommandDefault(defaultCommand),
  );

  const {
    targetForce,
    targetTorque,
    shankPosition: targetShankPosition,
    screwLength: targetScrewLength,
  } = command;

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const routineRunningState = useRobotRoutineRunningState({ isVizbot });

  const isRoutineRunning = routineRunningState !== null;

  const routineRunnerForce =
    routineRunnerGripperState?.targetForce ?? COMMAND_FORCE_N_DEFAULT;

  const routineRunnerTorque =
    routineRunnerGripperState?.targetTorque ?? COMMAND_TORQUE_NM_DEFAULT;

  const routineRunnerShankPosition =
    routineRunnerGripperState?.shankPosition ??
    COMMAND_SHANK_POSITION_MM_DEFAULT;

  const changeTargetForce = (action: React.SetStateAction<number>) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let newtons: number;

      if (typeof action === 'function') {
        newtons = action(previousState?.targetForce ?? COMMAND_FORCE_N_DEFAULT);
      } else {
        newtons = action;
      }

      return {
        ...previousState,
        targetForce: clamp(newtons, FORCE_RANGE.min, FORCE_RANGE.max),
      };
    });
  };

  const changeCommandKind = (action: React.SetStateAction<CommandKind>) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let commandKind: CommandKind;

      if (typeof action === 'function') {
        commandKind = action(
          previousState?.commandKind ?? COMMAND_KIND_DEFAULT,
        );
      } else {
        commandKind = action;
      }

      return {
        ...previousState,
        commandKind,
      };
    });
  };

  const changeTargetTorque = (
    action: React.SetStateAction<number> | undefined,
  ) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let newtonMeters: number | undefined;

      if (typeof action === 'function') {
        newtonMeters = action(
          previousState?.targetTorque ?? COMMAND_TORQUE_NM_DEFAULT,
        );
      } else {
        newtonMeters = action;
      }

      return {
        ...previousState,
        targetTorque: newtonMeters
          ? clamp(newtonMeters, TORQUE_RANGE.min, TORQUE_RANGE.max)
          : undefined,
      };
    });
  };

  const changeScrewLength = (action: React.SetStateAction<number>) => {
    setCommand((previousState) => {
      let length: number;

      if (typeof action === 'function') {
        length = action(
          previousState?.screwLength ?? COMMAND_SCREW_LENGTH_MM_DEFAULT,
        );
      } else {
        length = action;
      }

      return {
        ...previousState,
        screwLength: clamp(
          length,
          SCREW_LENGTH_RANGE.min,
          SCREW_LENGTH_RANGE.max,
        ),
      };
    });
  };

  const changeShankPosition = (
    action: React.SetStateAction<number> | undefined,
  ) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let position: number | undefined;

      if (typeof action === 'function') {
        position = action(
          previousState?.shankPosition ?? COMMAND_SHANK_POSITION_MM_DEFAULT,
        );
      } else {
        position = action;
      }

      return {
        ...previousState,
        shankPosition: position
          ? clamp(position, SHANK_POSITION_RANGE.min, SHANK_POSITION_RANGE.max)
          : undefined,
      };
    });
  };

  /* Make the temporary form state conform to the actual observed state
   * after actuation completes.
   *
   * For example, if the command was "grip to 10cm" but the actuation
   * resulted in a grasp of an object with width 15cm, we want the gripper
   * control state to be updated to 15cm once the actuation is complete.
   *
   * Accomplish this by marking the form as outdated, so the `useEffect`
   * will reset it.
   */
  const conformGripperControlStateToActualState = useCallback(
    (gripperRoutineRunnerState: OnRobotScrewdriverState) => {
      if (!gripperRoutineRunnerState) return;

      setCommand((previousState) => {
        return {
          ...previousState,
          shankPosition: gripperRoutineRunnerState.shankPosition,
          targetForce: gripperRoutineRunnerState.targetForce,
        } as OnRobotScrewdriverCommand;
      });

      setConformedState('updated');
    },
    [],
  );

  useEffect(() => {
    if (conformedState !== 'diverged' && routineRunnerGripperState) {
      conformGripperControlStateToActualState(routineRunnerGripperState);
    }
  }, [
    conformGripperControlStateToActualState,
    conformedState,
    routineRunnerGripperState,
  ]);

  // Disable range controls if actual is equal to target
  const isForceEqual = isApproximatelyEqual(
    routineRunnerForce,
    targetForce ?? FORCE_RANGE.min,
    FORCE_RANGE.step,
  );

  const isTorqueEqual = isApproximatelyEqual(
    routineRunnerTorque,
    targetTorque ?? TORQUE_RANGE.min,
    TORQUE_RANGE.step,
  );

  const isShankPositionEqual = isApproximatelyEqual(
    routineRunnerShankPosition,
    targetShankPosition ?? routineRunnerShankPosition,
    SHANK_POSITION_RANGE.step,
  );

  /** Enable target mode move control if any of the values are not equal to their target */
  const canApplyGripperChanges =
    !isRoutineRunning &&
    !isAnotherSessionMovingRobot &&
    (!isTorqueEqual || !isForceEqual || !isShankPositionEqual);

  // Gripper current values
  const actual = {
    routineRunnerForce,
    routineRunnerShankPosition,
    routineRunnerTorque,
  };

  const target = {
    targetForce: targetForce ?? FORCE_RANGE.min,
    targetTorque: targetTorque ?? TORQUE_RANGE.min,
    targetShankPosition: targetShankPosition ?? SHANK_POSITION_RANGE.min,
    targetScrewLength: targetScrewLength ?? SCREW_LENGTH_RANGE.min,
  };

  return {
    canApplyGripperChanges,
    changeCommandKind,
    changeTargetForce,
    changeTargetTorque,
    changeScrewLength,
    changeShankPosition,
    isShankPositionEqual,
    command,
    actual,
    target,
    conformGripperControlStateToActualState,
    isConnected: routineRunnerGripperState.isConnected,
    isDisabled:
      !routineRunnerGripperState.isConnected ||
      Boolean(routineRunnerGripperState.error),
    setConformedState,
  };
}
