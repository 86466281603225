import type { OnRobot3FG15Command, OR3FG15CommandKind } from '../..';
import {
  OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX,
  OR_3FG15_TARGET_FORCE_DEFAULT,
  OR_3FG15_FORCE_NEWTONS_MIN,
  OR_3FG15_FORCE_NEWTONS_MAX,
} from '../..';

const OR_3FG15_COMMAND_KIND_DEFAULT = 'OnRobot3FG15Command';
const OR_3FG15_GRIP_KIND_DEFAULT: OnRobot3FG15Command['gripKind'] = 'inward';

export const OR_3FG15_DIAMETER_DEFAULT = 0.1535;

export const OR_3FG15_COMMAND_DEFAULT: OnRobot3FG15Command = {
  kind: OR_3FG15_COMMAND_KIND_DEFAULT,
  gripKind: OR_3FG15_GRIP_KIND_DEFAULT,
  targetForce: OR_3FG15_TARGET_FORCE_DEFAULT,
  targetDiameter: OR_3FG15_DIAMETER_DEFAULT,
};

export const OR_3FG15_GRIP_FORCE_RANGE = {
  min: OR_3FG15_FORCE_NEWTONS_MIN,
  max: OR_3FG15_FORCE_NEWTONS_MAX,
};

export const OR_3FG15_FLEX_GRIP_FORCE_RANGE = {
  min: OR_3FG15_FORCE_NEWTONS_MIN,
  max: OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX,
};

export function getOR3FG15ForceRange(commandKind: OR3FG15CommandKind) {
  if (commandKind === 'flexGrip') {
    return OR_3FG15_FLEX_GRIP_FORCE_RANGE;
  }

  // default grip range
  return OR_3FG15_GRIP_FORCE_RANGE;
}

export function calculateOR3FG15TargetForce(
  commandKind: OR3FG15CommandKind,
  targetForce: number,
  setForce: (max: number) => void,
) {
  if (
    commandKind === 'flexGrip' &&
    targetForce > OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX
  ) {
    setForce(OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX);
  }

  return targetForce;
}
