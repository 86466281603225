import cx from 'classnames';

import { Button, Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { useEmergencyStopRobot, useIsRobotConnected } from '@sbrc/hooks';

import { NotificationsHeaderButton } from '../notifications/NotificationsHeaderButton';
import { RobotBadge } from '../robot-badge/RobotBadge';
import { useSoftwareBuildData } from '../software-update';

import { PlayPauseButton } from './PlayPauseButton';

interface AppHeaderProps {
  children: React.ReactNode;
  robot: Robot.ConvertedResponse;
  isVizbot?: boolean;
}

export default function AppHeader({
  children,
  robot,
  isVizbot,
}: AppHeaderProps) {
  // robot data
  const isRobotConnected = useIsRobotConnected({});
  const { emergencyStopRobot } = useEmergencyStopRobot({});

  // build data
  const buildData = useSoftwareBuildData({
    shouldReloadOnActiveBuildIdChange: true,
  });

  return (
    <header
      className={cx(
        'tw-fixed',
        'tw-top-0',
        'tw-left-0',
        'tw-right-0',
        'tw-h-[--page--header-height]',
        'tw-surface-elevated',
        'tw-flex',
        'tw-items-center',
        'tw-border-b',
        'tw-border-metal-30',
        'dark:tw-border-divider-primary',
        'tw-z-20',
        'tw-gap-16',
        // extend the header above the top of the viewport - on the iPad app this area is visible behind the OS status bar
        'tw-box-content',
        'tw-border-t-[--surface-elevated]',
        'tw-border-t-[40px]',
        '-tw-mt-40',
      )}
    >
      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pl-16',
          'tw-overflow-hidden',
          'tw-whitespace-nowrap',
          'tw-justify-end',
        )}
      >
        {children}
        <div className="tw-flex-1" />
      </div>

      <div className="tw-flex-none">
        <Button
          disabled={!isRobotConnected}
          variant="Filled"
          color="Red"
          onClick={() => emergencyStopRobot()}
          data-testid="app-header-estop-button"
        >
          <Icon kind="circleSlash" />
          <span>E-Stop</span>
        </Button>
      </div>

      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pr-16',
          'tw-overflow-hidden',
        )}
      >
        <div className="tw-flex-1" />

        <RobotBadge robot={robot} buildData={buildData} isVizbot={isVizbot} />

        <PlayPauseButton robot={robot} />

        <NotificationsHeaderButton />
      </div>
    </header>
  );
}
