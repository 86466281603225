import type { Integrations } from '@sb/remote-control/types';
import { Layout, Typography } from '@sb/ui/components';

export function InvalidConfigurationEquipmentManager({
  item,
}: Integrations.EquipmentManagerEditFormProps) {
  return (
    <Layout.Flex direction="vertical">
      <Typography>
        The configuration saved for “{item.name}” is not valid. Remove this
        configuration and recreate it.
      </Typography>
    </Layout.Flex>
  );
}
