import cx from 'classnames';
import { useState } from 'react';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Popper,
  SettingsGroupItem,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import type { DualGripperCompatibleGrippers } from '../types/Configuration';

interface QuickChangerItemProps {
  item: DualGripperCompatibleGrippers;
  onClick: () => void;
  onChange: (item: DualGripperCompatibleGrippers) => void;
  isDisabled: boolean;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function QuickChangerItem({
  item,
  onClick,
  onChange,
  isDisabled,
  getEquipmentByKind,
}: QuickChangerItemProps) {
  const otherOptions = (['NoGripper', 'OnRobot2FG7', 'OnRobot3FG15'] as const)
    .filter((kind) => kind !== item.kind)
    .map((kind) => {
      const frontend = getEquipmentByKind(kind);

      return {
        label: frontend.getDisplayName(),
        defaultItem: {
          ...frontend.getDefaultItem(),
          name: frontend.getDisplayName(),
        },
        kind,
        iconKind: frontend.iconKind,
      };
    });

  const equipmentFrontendImplementation = getEquipmentByKind(item.kind);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx('tw-flex', 'tw-gap-10')}>
      {item.kind === 'NoGripper' ? (
        <SettingsGroupItem isSeparated className="tw-flex-1">
          {equipmentFrontendImplementation.getDisplayName()}
        </SettingsGroupItem>
      ) : (
        <SettingsGroupNavigationItem
          isSeparated
          className="tw-flex-1"
          onClick={() => onClick()}
          label={
            <>
              <Icon kind={equipmentFrontendImplementation.iconKind} />
              <span>{item.name}</span>
            </>
          }
        />
      )}

      <Popper
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={
          <Menu>
            {otherOptions.map(({ defaultItem, label, iconKind }) => (
              <MenuItem
                key={label}
                onClick={() => onChange(defaultItem)}
                iconKind={iconKind}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        <Button
          size={44}
          className="tw-rounded-10"
          onClick={() => setIsOpen(!isOpen)}
          disabled={isDisabled}
        >
          <Icon kind="arrowSquarePath" />
          <span>Swap</span>
        </Button>
      </Popper>
    </div>
  );
}
