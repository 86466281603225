import { HoldableButton } from '@sb/design-system';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { OnRobotScrewdriverCommand } from '../..';
import type { useGripperControlState } from '../useGripperControlState';

interface CommandExecuteProps {
  isVizbot: boolean;
  gripperControlState: ReturnType<typeof useGripperControlState>;
}

export function CommandExecute({
  isVizbot,
  gripperControlState,
}: CommandExecuteProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    isVizbot,
    shouldStopBeforeRun: false,
  });

  const { setConformedState } = gripperControlState;

  const onHoldApplyChangesButton =
    (command: OnRobotScrewdriverCommand) => () => {
      runAdHocCommand({
        onRunCommand: async () => {
          const actuateGripper = await routineRunnerHandle.actuateDevice({
            command,
          });

          setConformedState('outdated');

          return actuateGripper;
        },

        onGuidedModeStop: async () => {},

        onError: (error) => {
          setToast({
            kind: 'error',
            message: `Error controlling screwdriver: ${error}`,
          });
        },
      });
    };

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Command (hold to apply)</h5>
      <div className="tw-flex tw-gap-12">
        <HoldableButton
          className="tw-rounded-6 tw-flex-1"
          onHold={onHoldApplyChangesButton({
            ...gripperControlState.command,
            commandKind: 'TIGHTEN',
          })}
          onRelease={stopGuidedMode}
          data-testid="gripper-widget-apply-changes-button"
        >
          Tighten
        </HoldableButton>
        <HoldableButton
          className="tw-rounded-6 tw-flex-1"
          onHold={onHoldApplyChangesButton({
            ...gripperControlState.command,
            commandKind: 'LOOSEN',
          })}
          onRelease={stopGuidedMode}
          data-testid="gripper-widget-apply-changes-button"
        >
          Loosen
        </HoldableButton>
      </div>
    </section>
  );
}
