import { useEffect } from 'react';

import {
  Button,
  GaugeStepperSingleValue,
  Icon,
  NavigationBar,
} from '@sb/design-system';
import type { DeviceCommand } from '@sb/integrations/device';
import type { OnRobotVGP20State } from '@sb/integrations/OnRobotVGP20';
import HelpPopover from '@sbrc/components/HelpPopover';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import { ORVGP20_CHANNELS, suctionAmountStringConvert } from '../constants';
import type { OnRobotVGP20Command } from '../types';

import { ApplyChangesButton } from './ApplyChangesButton';
import { SuctionChannelCheckbox } from './SuctionChannelCheckbox';
import { useGripperControlState } from './useGripperControlState';

interface WidgetControlBodyProps {
  headerTitle: string;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  defaultCommand?: OnRobotVGP20Command;
  vacuumState: OnRobotVGP20State;
  isVizbot: boolean;
}

export function WidgetControlBody({
  headerTitle,
  defaultCommand,
  vacuumState,
  onCommandChange,
  isVizbot,
}: WidgetControlBodyProps) {
  const {
    command,
    getIsEnabled,
    getSuctionPercentage,
    handleIsEnabledChange,
    handleOverallSuctionPercentageChange,
    overallSuctionPercentage,
    resetCommand,
  } = useGripperControlState(defaultCommand ?? null, vacuumState, isVizbot);

  useEffect(() => {
    onCommandChange?.(command);
  }, [onCommandChange, command]);

  const isAllEnabled = ORVGP20_CHANNELS.every(({ channel }) =>
    getIsEnabled(channel),
  );

  return (
    <WidgetView data-testid="onrobot-vacuum-control-widget">
      <NavigationBar>{headerTitle}</NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-px-16">
        <section>
          <h5 className="tw-heading-40 tw-pl-16">
            <span>Enabled Channels</span>
            <HelpPopover message="Select which channels are enabled" />
          </h5>

          <div className="tw-grid tw-grid-cols-2 tw-gap-12 tw-mb-12">
            {ORVGP20_CHANNELS.map(({ channel, name }) => (
              <SuctionChannelCheckbox
                key={channel}
                name={name}
                isChecked={getIsEnabled(channel)}
                onChange={handleIsEnabledChange(channel)}
                suctionPercentage={getSuctionPercentage(channel)}
              />
            ))}
          </div>
        </section>

        <div className="tw-flex tw-gap-12">
          <Button
            className="tw-rounded-6 tw-flex-1"
            onClick={() => handleIsEnabledChange('*')(!isAllEnabled)}
          >
            {isAllEnabled ? 'Disable all' : 'Enable all'}
          </Button>
          <Button
            className="tw-rounded-6 tw-flex-1"
            onClick={resetCommand}
            disabled={!command}
          >
            <Icon kind="arrowUturnLeft" />
            <span>Reset all</span>
          </Button>
        </div>

        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">
            <span>Pressure for enabled channels</span>
            <HelpPopover message="Target pressure to apply on the enabled channels" />
          </h5>

          <GaugeStepperSingleValue
            kind="Filled"
            value={overallSuctionPercentage}
            min={0.01}
            max={1}
            step={0.01}
            onChange={handleOverallSuctionPercentageChange}
            valueToString={suctionAmountStringConvert}
          />
        </section>
      </div>

      <ApplyChangesButton
        isVizbot={isVizbot}
        command={command}
        resetCommand={resetCommand}
      />
    </WidgetView>
  );
}
