import cx from 'classnames';
import { useId } from 'react';

import type { SettingsGroupProps } from '@sb/design-system';
import {
  NumberInput,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';
import type { DistanceUnitInfo, EulerPose } from '@sbrc/utils';
import { getDistanceUnitInfo } from '@sbrc/utils';

const TOOL_POSE_LABEL_LIST: Array<{
  prop: keyof EulerPose;
  label: string;
  colorClassName: string;
  kind: 'distance' | 'angle';
}> = [
  {
    prop: 'X',
    label: 'X',
    colorClassName: 'tw-text-red',
    kind: 'distance',
  },
  {
    prop: 'Y',
    label: 'Y',
    colorClassName: 'tw-text-green',
    kind: 'distance',
  },
  {
    prop: 'Z',
    label: 'Z',
    colorClassName: 'tw-text-blue',
    kind: 'distance',
  },
  {
    prop: 'Rx',
    label: 'Roll',
    colorClassName: 'tw-text-red',
    kind: 'angle',
  },
  {
    prop: 'Ry',
    label: 'Pitch',
    colorClassName: 'tw-text-green',
    kind: 'angle',
  },
  {
    prop: 'Rz',
    label: 'Yaw',
    colorClassName: 'tw-text-blue',
    kind: 'angle',
  },
];

const ROTATION_UNIT_INFO = {
  abbreviation: 'deg',
  decimalPlaces: 4,
  fromMeters: (v: any) => v,
  toMeters: (v: any) => v,
};

interface EulerPoseSettingsGroupProps
  extends Omit<SettingsGroupProps, 'onChange'> {
  distanceUnitInfo?: DistanceUnitInfo;
  value: EulerPose;
  onChange: (pose: EulerPose) => void;
}

export function EulerPoseSettingsGroup({
  distanceUnitInfo = getDistanceUnitInfo('millimeter'),
  value,
  onChange,
  ...rest
}: EulerPoseSettingsGroupProps) {
  const inputIDPrefix = useId();

  return (
    <SettingsGroup {...rest}>
      {TOOL_POSE_LABEL_LIST.map((position) => {
        const { label, colorClassName, prop, kind } = position;

        const inputID = `${inputIDPrefix}-${prop}`;
        const fieldValue = value[prop];

        const unitInfo =
          kind === 'distance' ? distanceUnitInfo : ROTATION_UNIT_INFO;

        return (
          <SettingsGroupItem key={prop}>
            <label
              className={cx('tw-flex-1', colorClassName)}
              htmlFor={inputID}
            >
              {label}
            </label>

            <NumberInput
              id={inputID}
              size={32}
              variant="Gray"
              alignment="Center"
              className="tw-w-128"
              decimalPlaces={unitInfo.decimalPlaces}
              value={unitInfo.fromMeters(fieldValue)}
              onChange={(newValue) =>
                onChange({ ...value, [prop]: unitInfo.toMeters(newValue || 0) })
              }
            />

            <span className="tw-text-label-secondary tw-text-right tw-w-52">
              {unitInfo.abbreviation}
            </span>
          </SettingsGroupItem>
        );
      })}
    </SettingsGroup>
  );
}
