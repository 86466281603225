import type { Integrations, RoutineVariable } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import { OR3FG15_SHORTNAME } from './constants';
import { OnRobot3FG15EquipmentManager } from './equipment-manager/OnRobot3FG15EquipmentManager';
import { ON_ROBOT_3FG15_GRIPPERS_CONFIGURATION_DEFAULT } from './types/Configuration';
import { OnRobot3FG15Widget } from './widget/OnRobot3FG15Widget';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    {
      kind: 'gripperState',
      selector: 'gripperState.diameterMeters',
      variableKind: 'number',
      displayName: 'Diameter',
      variableName: 'kinematicState.gripperState.diameterMeters',
      userIdentifier: 'Gripper_diameterMeters',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
  ];

export const onRobot3FG15Implementation: Integrations.IntegrationFrontend<'OnRobot3FG15'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'equipment3FingerGripper',
    getSupportedSteps: () => ['ActuateGripper'],
    getDisplayName: () => {
      return 'OnRobot 3FG15';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => OR3FG15_SHORTNAME,
    getIntegrationVariables: () => INTEGRATION_VARIABLES,
    getDefaultItem: () => ON_ROBOT_3FG15_GRIPPERS_CONFIGURATION_DEFAULT,
    Widget: OnRobot3FG15Widget,
    EquipmentManagerEditForm: OnRobot3FG15EquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobot3FG15') {
        return undefined;
      }

      if (!state.isConnected) {
        return 'disconnected';
      }

      if (!state.isCalibrationOk) {
        return { kind: 'error', message: 'Uncalibrated' };
      }

      return state.isBusy ? 'busy' : 'idle';
    },
    getTCPOffsetOptions: (config) => {
      return [config.name];
    },
  };
