import cx from 'classnames';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';

import {
  Menu,
  MenuItem,
  NavigationBar,
  NavigationBarBackButton,
  NavigationBarButton,
  NavigationBarMenuButton,
  TextInput,
  useConfirmModal,
} from '@sb/design-system';
import { ZERO_POSE } from '@sb/geometry';
import { ConfigureTCPTransform } from '@sb/integrations/frontend/ConfigureTCPTransform';
import { isDuplicateName } from '@sbrc/utils';

import type { CustomGripperTooltipConfiguration } from '../types/CustomGripperConfiguration';

interface EditTooltipProps {
  initialValue: CustomGripperTooltipConfiguration;
  otherTooltips: CustomGripperTooltipConfiguration[];
  isDisabled: boolean;
  onBack: () => void;
  onUpdate: (value: CustomGripperTooltipConfiguration) => void;
  onDelete: () => void;
}

export function EditTooltip({
  initialValue,
  otherTooltips,
  isDisabled,
  onBack,
  onUpdate,
  onDelete,
}: EditTooltipProps) {
  const [value, setValue] = useState(initialValue);

  const [deleteAlert, openDeleteAlert] = useConfirmModal({
    body: 'Are you sure you want delete this tooltip?',
    onConfirm: onDelete,
  });

  const invalidNameError = useMemo(() => {
    if (isDuplicateName(value.name, '')) {
      return 'Name must not be empty';
    }

    if (otherTooltips.some((t) => isDuplicateName(t.name, value.name))) {
      return 'Name must be unique';
    }

    return null;
  }, [value.name, otherTooltips]);

  return (
    <>
      <NavigationBar
        contentLeft={<NavigationBarBackButton onClick={onBack} />}
        contentRight={
          <>
            <NavigationBarMenuButton
              content={
                <Menu>
                  <MenuItem
                    className="tw-text-red"
                    iconKind="trash"
                    onClick={openDeleteAlert}
                    disabled={isDisabled || otherTooltips.length === 0}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              }
            />

            <NavigationBarButton
              onClick={() => onUpdate(value)}
              disabled={
                isEqual(value, initialValue) || invalidNameError !== null
              }
            >
              Save
            </NavigationBarButton>
          </>
        }
      >
        Tooltip
      </NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-px-24 tw-mb-24 tw-flex tw-flex-col tw-gap-32">
        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">Name</h5>
          <TextInput
            size={44}
            className={cx(
              'tw-rounded-10',
              invalidNameError && 'tw-outline-error',
            )}
            value={value.name}
            placeholder="None"
            onChange={(name) => {
              setValue({
                ...value,
                name,
              });
            }}
            disabled={isDisabled}
          />
          {invalidNameError && (
            <p className="tw-mt-8 tw-text-red tw-text-13 tw-pl-16">
              {invalidNameError}
            </p>
          )}
        </section>

        <ConfigureTCPTransform
          value={value.tcpTransform}
          onChange={(tcpTransform) => setValue({ ...value, tcpTransform })}
          isDisabled={isDisabled}
          defaultValue={ZERO_POSE}
        />
      </div>

      {deleteAlert}
    </>
  );
}
