import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';

interface NotificationListMoreMenuProps {
  newIDs: string[];
  allIDs: string[];
  onStatusUpdate: (ids: string[], status: Notification.Status) => void;
}

export function NotificationListMoreMenu({
  newIDs,
  allIDs,
  onStatusUpdate,
}: NotificationListMoreMenuProps) {
  return (
    <NavigationBarMenuButton
      content={
        <Menu>
          <MenuItem
            iconKind="checkmarkCircle"
            disabled={newIDs.length === 0}
            onClick={() => onStatusUpdate(newIDs, 'viewed')}
          >
            Mark all as viewed
          </MenuItem>
          <MenuItem
            iconKind="box"
            disabled={allIDs.length === 0}
            onClick={() => onStatusUpdate(allIDs, 'archived')}
          >
            Archive all
          </MenuItem>
        </Menu>
      }
    />
  );
}
