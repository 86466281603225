import { zip } from 'lodash';

import {
  cameraPoseFromWristPose,
  cartesianPoseToMatrix4,
  type CartesianPose,
  matrix4ToCartesianPose,
  differenceBetweenPoses,
  averagePoses,
} from '@sb/geometry';
import { forwardKinematics } from '@sb/motion-planning';

import type { WristCameraAccuracyCalibrationEntry } from '../types/Configuration';

export function getChessboardPose(
  cameraPose: CartesianPose,
  cameraRelativeToChessboard: CartesianPose, // The extrinsic matrix returned from openCV's solvePNP
): CartesianPose {
  // Matrix which transforms from camera coordinates to base coordinates
  const cameraWorldMat = cartesianPoseToMatrix4(cameraPose);

  // Matrix which transforms from chessboard coordinate to camera coordinates
  const chessboardCameraMat = cartesianPoseToMatrix4(
    cameraRelativeToChessboard,
  );

  const chessboardWorldPose = cameraWorldMat.multiply(chessboardCameraMat);

  return matrix4ToCartesianPose(chessboardWorldPose);
}

export function buildAccuracyCalibrationList(
  accuracyCalibrationEntries: WristCameraAccuracyCalibrationEntry[],
) {
  const chessboardPoses: CartesianPose[] = accuracyCalibrationEntries.map(
    (entry) => {
      const wristPose = forwardKinematics(entry.jointAngles);
      const cameraPose = cameraPoseFromWristPose(wristPose);

      const chessboardPose = getChessboardPose(
        cameraPose,
        entry.cameraChessboardTransform,
      );

      return chessboardPose;
    },
  );

  const averageChessboardPose = averagePoses(chessboardPoses);

  for (const [entry, chessboardPose] of zip(
    accuracyCalibrationEntries,
    chessboardPoses,
  )) {
    const offset = differenceBetweenPoses(
      chessboardPose!,
      averageChessboardPose,
    );

    entry!.offset = offset;
  }

  return accuracyCalibrationEntries;
}
