import { SettingsGroup, SettingsGroupNavigationItem } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';
import { useFeatureFlag } from '@sbrc/hooks';

import { ExtrinsicCalibration } from './ExtrinsicCalibration';

export function WristCameraEquipmentManager({
  item,
  onUpdate,
  // isFormDisabled,
  setChildContent,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'WristCamera') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const isExtrinsicCalibrationEnabled = useFeatureFlag(
    'cameraExtrinsicCalibration',
  );

  if (!isExtrinsicCalibrationEnabled) {
    return null;
  }

  return (
    <section>
      <h5 className="tw-heading-40 tw-pl-16">Calibration</h5>
      <SettingsGroup>
        <SettingsGroupNavigationItem
          label="Extrinsic calibration"
          onClick={() =>
            setChildContent(
              <ExtrinsicCalibration
                item={item}
                onBack={() => setChildContent(null)}
                onCapture={onUpdate}
              />,
            )
          }
        >
          Calibrate
        </SettingsGroupNavigationItem>
      </SettingsGroup>
    </section>
  );
}
