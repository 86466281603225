import type { WristCamera } from '@sb/integrations/camera/types';

export const DEFAULT_WRIST_CAMERA: WristCamera = {
  kind: 'wristCamera',
  name: 'Wrist Camera',
  // realsense defaults
  brightness: 0,
  contrast: 50,
  exposure: 166,
  sharpness: 50,
  hue: 0,
  whiteBalance: 4600,
  autoWhiteBalance: true,
};
