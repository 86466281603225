import { useEffect, useRef, useState } from 'react';

interface SVGOverlayProps {
  children: React.ReactNode;
}

export function SVGOverlay({ children }: SVGOverlayProps) {
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const canvasEl =
      svgRef.current?.parentElement?.getElementsByTagName<'canvas'>(
        'canvas',
      )[0];

    if (canvasEl) {
      const updateCanvasSize = () => {
        setCanvasSize({ width: canvasEl.width, height: canvasEl.height });
      };

      updateCanvasSize();

      const m = new MutationObserver(updateCanvasSize);

      m.observe(canvasEl, { attributes: true });

      return () => m.disconnect();
    }

    return undefined;
  }, []);

  return (
    <svg
      ref={svgRef}
      className="tw-absolute tw-inset-0 tw-w-full tw-h-full"
      viewBox={`0 0 ${canvasSize.width} ${canvasSize.height}`}
    >
      {children}
    </svg>
  );
}
