import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import type { User } from '@sb/remote-control/types';
import { Button, ModalContent, Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import { useToast } from '@sbrc/hooks';
import { updateUser } from '@sbrc/services';
import { getDistanceUnitInfo } from '@sbrc/utils';

import { SelectDistanceUnit } from '../units';

import styles from './UserSettingsForm.module.css';

interface UserSettingsFormProps {
  initialSettings: User.ConvertedResponse;
  onClose: (forceClose?: boolean) => void;
  setHasChanges: (hasChanges: boolean) => void;
}

export function UserSettingsForm({
  initialSettings,
  onClose,
  setHasChanges,
}: UserSettingsFormProps) {
  const { setToast } = useToast();
  const [settings, setSettings] = useState(initialSettings);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasChanges = !isEqual(settings, initialSettings);

  useEffect(() => setHasChanges(hasChanges), [hasChanges, setHasChanges]);

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      await updateUser(initialSettings.id, {
        distanceUnit: settings.distanceUnit,
      });

      setToast({ kind: 'success', message: 'User settings updated' });
      onClose(true);
    } catch (error) {
      setToast({
        kind: 'error',
        message: `User settings update failed: ${error}`,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <ModalContent className={styles.content}>
        <Typography component="h6" className={margin.bottom.medium}>
          Units
        </Typography>
        <SelectDistanceUnit
          label="Distances"
          activeLabel={getDistanceUnitInfo(settings.distanceUnit).name}
          className={styles.select}
          value={settings.distanceUnit}
          onSelect={(distanceUnit) =>
            setSettings({ ...settings, distanceUnit })
          }
        />
      </ModalContent>
      <div className={styles.footer}>
        <Button type="button" onClick={() => onClose()} variant="gray">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting || !hasChanges}>
          Save
        </Button>
      </div>
    </form>
  );
}
