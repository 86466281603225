const FRAME_MIMETYPE = 'image/jpeg';

// matches boundary defined in camera-bot main.py
const FRAME_BOUNDARY_BYTES = '--frame\r\n'
  .split('')
  .map((c) => c.charCodeAt(0));

const FRAME_HEADER_BYTES = [
  ...FRAME_BOUNDARY_BYTES,
  ...`Content-Type: ${FRAME_MIMETYPE}\r\n\r\n`
    .split('')
    .map((c) => c.charCodeAt(0)),
];

const MAX_BUFFER_SIZE = 1024 * 1024;

export class CameraStreamFramesTransformer {
  private bufferLength = 0;

  private bufferChunks: Uint8Array[] = [];

  /** add a chunk to the buffer */
  private addChunk(chunk: Uint8Array) {
    this.bufferChunks.push(chunk);
    this.bufferLength += chunk.length;
  }

  /** take chunks from the buffer, up to `byteCount` bytes */
  private removeChunks(byteCount: number): Uint8Array[] {
    const result: Uint8Array[] = [];

    while (byteCount > 0) {
      const chunk = this.bufferChunks[0];

      if (!chunk) {
        break;
      } else if (byteCount >= chunk.length) {
        byteCount -= chunk.length; // eslint-disable-line no-param-reassign
        result.push(chunk);
        this.bufferChunks.shift();
        this.bufferLength -= chunk.length;
      } else {
        // take a partial chunk
        result.push(chunk.subarray(0, byteCount));
        this.bufferChunks[0] = chunk.subarray(byteCount);
        this.bufferLength -= byteCount;
        break;
      }
    }

    return result;
  }

  /** Return the value at position */
  private valueAt(index: number): number {
    for (const chunk of this.bufferChunks) {
      if (index >= chunk.length) {
        index -= chunk.length; // eslint-disable-line no-param-reassign

        if (index < 0) {
          return NaN;
        }
      } else {
        return chunk[index];
      }
    }

    return NaN;
  }

  /** Compare start of buffer with `values` */
  private startsWith(values: number[]): boolean {
    return values.every((value, index) => {
      return this.valueAt(index) === value;
    });
  }

  /**
   * Find `needle` in buffer.
   * Boyer-Moore-Horspool algorithm, copied from https://gist.github.com/jhermsmeier/2138865
   */
  private indexOf(needle: number[], start: number): number {
    let offset = start;
    const nlen = needle.length;
    let hlen = this.bufferLength - offset;

    if (nlen <= 0 || hlen <= 0) return -1;

    if (offset + nlen > this.bufferLength) return -1;

    const last = nlen - 1;
    const skip: Record<number, number> = {};

    for (let scan = 0; scan < last; scan += 1) {
      skip[needle[scan]] = last - scan;
    }

    while (hlen >= nlen) {
      for (
        let scan = last;
        this.valueAt(offset + scan) === needle[scan];
        scan -= 1
      ) {
        if (scan === 0) {
          return offset;
        }
      }

      const jump = skip[this.valueAt(offset + last)] ?? nlen;
      hlen -= jump;
      offset += jump;
    }

    return -1;
  }

  private transformStream = new TransformStream<Uint8Array, Blob>({
    transform: (chunk, controller) => {
      this.addChunk(chunk);

      while (true) {
        const frameBlob = this.getFrameBlob();

        if (frameBlob) {
          controller.enqueue(frameBlob);
        } else {
          break;
        }
      }
    },
  });

  // the chunks should always start with a frame boundary;
  // we want to search for the *second* frame boundary
  private searchBoundaryStart = FRAME_BOUNDARY_BYTES.length;

  /** remove the first complete frame in the buffer, and return it */
  private getFrameBlob(): Blob | undefined {
    const frameBoundaryIndex = this.indexOf(
      FRAME_BOUNDARY_BYTES,
      this.searchBoundaryStart,
    );

    if (frameBoundaryIndex === -1) {
      // gone too long without seeing a frame boundary
      if (this.bufferLength > MAX_BUFFER_SIZE) {
        // discard current buffer
        this.removeChunks(this.bufferLength);
        // reset search position for next time
        this.searchBoundaryStart = FRAME_BOUNDARY_BYTES.length;

        // return an invalid frame
        return new Blob();
      }

      // frame boundary not found; move on the search position for next time
      this.searchBoundaryStart = Math.max(
        this.searchBoundaryStart,
        this.bufferLength - FRAME_BOUNDARY_BYTES.length,
      );

      return undefined;
    }

    // reset search position for next time
    this.searchBoundaryStart = FRAME_BOUNDARY_BYTES.length;

    const isExpectedHeader = this.startsWith(FRAME_HEADER_BYTES);

    if (!isExpectedHeader) {
      // discard this frame, it's useless
      this.removeChunks(frameBoundaryIndex);

      // return an invalid frame
      return new Blob();
    }

    // discard header bytes
    this.removeChunks(FRAME_HEADER_BYTES.length);

    const blobChunks = this.removeChunks(
      frameBoundaryIndex - FRAME_HEADER_BYTES.length,
    );

    return new Blob(blobChunks, { type: FRAME_MIMETYPE });
  }

  public async start(
    readableStream: ReadableStream<Uint8Array>,
    onWrite: (blob: Blob) => Promise<void>,
  ) {
    /**
     * ideally we would do this:
     * ```
     * await readableStream
     *   .pipeThrough(this.transformStream)
     *   .pipeTo(new WritableStream({ write: onWrite }));
     * ```
     * but that currently triggers unhandled promise rejections in Safari when the readableStream aborts
     * so doing this instead
     * (see https://bugs.webkit.org/show_bug.cgi?id=215771)
     */

    const runReadLoop = async () => {
      const writer = this.transformStream.writable.getWriter();
      const reader = readableStream.getReader();

      try {
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          await writer.ready;
          await writer.write(value);
        }
      } finally {
        reader.releaseLock();
        writer.releaseLock();
      }
    };

    const write = this.transformStream.readable.pipeTo(
      new WritableStream({ write: onWrite }),
    );

    await Promise.race([runReadLoop(), write]);
  }
}
