import {
  NavigationBar,
  NavigationBarBackButton,
  NavigationBarButton,
} from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import { ZERO_POSE } from '@sb/geometry';
import { CameraStream } from '@sbrc/components/camera';
import { ChessboardOverlay } from '@sbrc/components/camera/stream/ChessboardOverlay';
import { useRobotJointAnglesDegrees } from '@sbrc/hooks';

import type { WristCameraConfiguration } from '../types/Configuration';

import { buildAccuracyCalibrationList } from './buildAccuracyCalibrationList';
import { CalibrationMessageOverlay } from './CalibrationMessageOverlay';
import { useVisionChessboardCorners } from './useVisionChessboardCorners';

export const EXTRINSIC_CALIBRATION_SAMPLES = 12;
interface ExtrinsicCalibrationProps {
  item: WristCameraConfiguration;
  onBack: () => void;
  onCapture: (item: WristCameraConfiguration) => void;
}

export function ExtrinsicCalibration({
  item,
  onBack,
  onCapture,
}: ExtrinsicCalibrationProps) {
  const jointAngles = useRobotJointAnglesDegrees({ isVizbot: false });

  const captureCalibrationEntry = (extrinsics: CartesianPose): void => {
    if (jointAngles == null) {
      throw new Error('Joint angles are not available');
    }

    const newAccuracyCalibration = {
      jointAngles,
      cameraChessboardTransform: extrinsics,
      timestamp: Date.now().toString(),
      offset: ZERO_POSE,
    };

    // Build a list of calibration offsets for each set of joint angles
    onCapture({
      ...item,
      accuracyCalibration: buildAccuracyCalibrationList([
        ...item.accuracyCalibration,
        newAccuracyCalibration,
      ]),
    });
  };

  const { chessboardCorners, handleCapture } = useVisionChessboardCorners({
    isOpen: true,
    onCapture: captureCalibrationEntry,
  });

  const isFound = chessboardCorners != null && chessboardCorners.length > 0;

  return (
    <>
      <NavigationBar
        contentLeft={<NavigationBarBackButton onClick={onBack} />}
        contentRight={
          <NavigationBarButton disabled={!isFound} onClick={handleCapture}>
            Capture
          </NavigationBarButton>
        }
      >
        Extrinsic calibration
      </NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-px-24 tw-flex tw-flex-col">
        <div className="tw-max-w-320 tw-self-center">
          <CameraStream>
            {chessboardCorners && (
              <>
                <ChessboardOverlay points={chessboardCorners} />
                <CalibrationMessageOverlay isFound={isFound} />
              </>
            )}
          </CameraStream>
        </div>

        {item.accuracyCalibration.length > 0 && (
          <div>
            {item.accuracyCalibration.length} calibration samples taken out of{' '}
            {EXTRINSIC_CALIBRATION_SAMPLES}
          </div>
        )}
      </div>
    </>
  );
}
