import cx from 'classnames';

import {
  SettingsGroup,
  SettingsGroupItem,
  Button,
  Icon,
} from '@sb/design-system';
import type { FirmwareBuildData } from '@sbrc/hooks/softwareUpdate/useFirmwareUpdateStatus';
import { executeHostCommand } from '@sbrc/services';

import { FirmwareJointStatus } from './FirmwareJointStatus';
import type { SoftwareBuildData } from './useSoftwareBuildData';

const FIRMWARE_JOINTS_ALL = [0, 1, 2, 3, 4, 5, 6, 10, 12, 13];

interface FirmwareMismatchPanelProps {
  buildData: SoftwareBuildData;
  firmwareBuildData: FirmwareBuildData;
}

export function FirmwareMismatchPanel({
  buildData,
  firmwareBuildData,
}: FirmwareMismatchPanelProps) {
  const {
    jointUpdateStatus,
    isJointInstallInProgress,
    shouldIndicateFirmwareMismatch,
  } = firmwareBuildData;

  const { activeBuildID } = buildData;

  const jointNamesRequireUpdate = jointUpdateStatus
    .filter((joint) => joint.status === 'requiresUpdate')
    .map((requireUpdateJoint) => requireUpdateJoint.jointName);

  const firmwareMismatchMessage = (() => {
    const jointCount = jointNamesRequireUpdate.length;

    if (jointCount === 1) {
      return `board ${jointNamesRequireUpdate[0]} differs`;
    }

    if (jointCount === 2) {
      return `${jointNamesRequireUpdate.join(' and ')} differ`;
    }

    // 3 or more joints
    const jointNames = [...jointNamesRequireUpdate];
    const lastJoint = jointNames.pop();

    return `boards ${jointNames.join(', ')} and ${lastJoint} differ`;
  })();

  const jointCurrentlyInstalling = jointUpdateStatus.find(
    ({ status }) => status === 'installing',
  );

  if (shouldIndicateFirmwareMismatch) {
    return (
      <>
        <SettingsGroup>
          <SettingsGroupItem isSeparated>
            <div
              className={cx('tw-py-12', 'tw-flex', 'tw-flex-col', 'tw-gap-4')}
              style={{ whiteSpace: 'normal' }}
            >
              <p>Firmware mismatch</p>

              <p
                className={cx(
                  'tw-text-13',
                  'tw-text-label-tertiary',
                  'tw-mt-2',
                )}
              >
                The firmware on {firmwareMismatchMessage} from the software
                running on the robot. Discrepencies in firmware can cause the
                robot to act in unpredictable ways.
              </p>
            </div>
          </SettingsGroupItem>
        </SettingsGroup>

        <Button
          variant="Filled"
          className="tw-rounded-10"
          onClick={() => {
            executeHostCommand({
              command: 'installFirmware',
              installFirmware: {
                force: true,
                joints: FIRMWARE_JOINTS_ALL,
                build: activeBuildID,
              },
            });
          }}
          disabled={isJointInstallInProgress}
        >
          <Icon kind="arrowDownSquare" />
          <span>
            {isJointInstallInProgress
              ? `Installing ${jointCurrentlyInstalling?.jointName}`
              : 'Update now'}
          </span>
        </Button>

        <FirmwareJointStatus buildData={buildData} />
      </>
    );
  }

  return null;
}
