import { useCallback, useEffect, useState } from 'react';

import type { SoftwareUpdateData } from '@sb/feathers-types';
import { wait } from '@sb/utilities';
import { joints } from '@sb/utilities/src/joints';
import { useFeatureFlag } from '@sbrc/hooks';
import { getSoftwareUpdateData, requestSoftwareInstall } from '@sbrc/services';

export interface SoftwareBuildData extends Partial<SoftwareUpdateData> {
  timeChecked: Date | null;
  isInstallingSoftware: boolean;
  onRequestSoftwareInstall: () => void;
  selectedJointsForUpdate: number[];
  setSelectedJointsForUpdate: (selectedJoints: number[]) => void;
  isSoftwareUpdateAvailable: boolean;
}

const INITIAL_BUILD_DATA: SoftwareBuildData = {
  timeChecked: null,
  isInstallingSoftware: false,
  onRequestSoftwareInstall: () => {},
  selectedJointsForUpdate: [],
  setSelectedJointsForUpdate: () => {},
  isSoftwareUpdateAvailable: false,
};

interface UseSoftwareBuildDataArgs {
  shouldReloadOnActiveBuildIdChange?: boolean;
}

let runningActiveBuildId: string | undefined;

export const useSoftwareBuildData = ({
  shouldReloadOnActiveBuildIdChange,
}: UseSoftwareBuildDataArgs): SoftwareBuildData => {
  const enableFirmwareUpdates = useFeatureFlag('enableFirmwareUpdates');

  const [buildData, setBuildData] =
    useState<SoftwareBuildData>(INITIAL_BUILD_DATA);

  const [hasRequestedSoftwareInstall, setHasRequestedSoftwareInstall] =
    useState<boolean>(false);

  const [selectedJointsForUpdate, setSelectedJointsForUpdate] = useState<
    number[]
  >(joints.map(({ jointNumber }) => jointNumber));

  const onRequestSoftwareInstall = useCallback(async () => {
    setHasRequestedSoftwareInstall(true);

    await requestSoftwareInstall(
      enableFirmwareUpdates ? selectedJointsForUpdate : [],
    );
  }, [enableFirmwareUpdates, selectedJointsForUpdate]);

  const isInstallingSoftware =
    hasRequestedSoftwareInstall ||
    buildData.isSoftwareInstallRequested ||
    buildData.status === 'installing';

  const isSoftwareUpdateAvailable: boolean =
    (buildData.status === 'downloaded' || buildData.status === 'installing') &&
    buildData.targetBuildID !== undefined &&
    buildData.targetBuildID !== buildData.activeBuildID;

  useEffect(() => {
    let cancelled = false;

    const fetchBuildDataLoop = async () => {
      while (!cancelled) {
        try {
          const softwareUpdateData = await getSoftwareUpdateData();

          if (cancelled) {
            break;
          }

          setBuildData({
            ...INITIAL_BUILD_DATA,
            ...softwareUpdateData,
            timeChecked: new Date(),
          });

          if (
            !softwareUpdateData ||
            softwareUpdateData.isSoftwareInstallRequested ||
            softwareUpdateData.status === 'installing' ||
            softwareUpdateData.status === 'installed'
          ) {
            setHasRequestedSoftwareInstall(false);
          }

          if (shouldReloadOnActiveBuildIdChange) {
            const activeBuildID =
              softwareUpdateData?.activeBuildID ??
              // if undefined then feathers-bot is running a different version that remote-control
              // (i.e. during upgrade)
              'version-mismatch';

            if (runningActiveBuildId === undefined) {
              runningActiveBuildId = activeBuildID;
            }

            if (runningActiveBuildId !== activeBuildID) {
              window.location.reload();
            }
          }

          const intervalTime = isInstallingSoftware ? 2_000 : 30_000;

          await wait(intervalTime);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('Failed to get SoftwareUpdateData', error);
          await wait(2_000);
        }
      }
    };

    fetchBuildDataLoop();

    return () => {
      cancelled = true;
    };
  }, [shouldReloadOnActiveBuildIdChange, isInstallingSoftware]);

  return {
    ...buildData,
    isInstallingSoftware,
    onRequestSoftwareInstall,
    selectedJointsForUpdate,
    setSelectedJointsForUpdate,
    isSoftwareUpdateAvailable,
  };
};
