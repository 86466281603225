import type { Integrations } from '@sb/remote-control/types';

import { CustomIOEquipmentManager } from './equipment-manager/CustomIOEquipmentManager';
import { CustomIOConfiguration } from './types/Configuration';

export const customIOImplementation: Integrations.IntegrationFrontend<'CustomIO'> =
  {
    iconKind: 'connectorSevenPinRound',
    getIntegrationType: () => 'Other',
    getSupportedSteps: () => [],
    getDisplayName: () => {
      return 'Custom IO Device';
    },
    getManufacturerName: () => {
      return 'Custom IO';
    },
    getShortName: () => {
      return 'IO Device';
    },
    getIntegrationVariables: () => [],
    getDefaultItem: () => CustomIOConfiguration.parse({ kind: 'CustomIO' }),
    EquipmentManagerEditForm: CustomIOEquipmentManager,
  };
