import type { Integrations } from '@sb/remote-control/types';

import { WristCameraEquipmentManager } from './equipment-manager/WristCameraEquipmentManager';
import { WristCameraConfiguration } from './types/Configuration';

const DEFAULT_NAME = 'Built-in Wrist Camera';

export const wristCameraImplementation: Integrations.IntegrationFrontend<'WristCamera'> =
  {
    iconKind: 'equipmentCamera',
    getIntegrationType: () => 'Camera',
    canAddAsNewIntegration: (existing) =>
      !existing.some((otherItem) => otherItem.getShortName() === DEFAULT_NAME),
    getSupportedSteps: () => [],
    getDisplayName: () => DEFAULT_NAME,
    getManufacturerName: () => 'Camera',
    getShortName: () => DEFAULT_NAME,
    getIntegrationVariables: () => [],
    getDefaultItem: () =>
      WristCameraConfiguration.parse({ kind: 'WristCamera' }),
    EquipmentManagerEditForm: WristCameraEquipmentManager,
  };
