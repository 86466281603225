import type { Point2D } from '@sb/routine-runner';

import { SVGOverlay } from './SVGOverlay';

interface ChessboardOverlayProps {
  points: Point2D[];
}

export function ChessboardOverlay({ points }: ChessboardOverlayProps) {
  return (
    <SVGOverlay>
      {points.map(({ x, y }, index) => {
        const key = `${index}`;

        return (
          <circle
            key={key}
            cx={x}
            cy={y}
            r={6}
            fill="none"
            strokeWidth={4}
            className="tw-stroke-yellow-20/60"
          />
        );
      })}
    </SVGOverlay>
  );
}
