import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { Button, Icon } from '@sb/design-system';
import type { CameraIntegration } from '@sb/integrations/camera/types';
import { useToast } from '@sbrc/hooks';
import { updateCameraSettings } from '@sbrc/services/camera-client';

import { DEFAULT_WRIST_CAMERA } from './wrist-camera/consts';
import { WristCameraSettings } from './wrist-camera/WristCameraSettings';

const DEBOUNCE_UPDATE_CAMERA_SETTINGS_MS = 100;

interface CameraSettingsProps {
  isDisabled?: boolean;
  value: CameraIntegration;
  onChange: (camera: CameraIntegration) => void;
  doUpdateCameraSettings?: boolean;
}

export function CameraSettings({
  isDisabled,
  value,
  onChange,
  doUpdateCameraSettings,
}: CameraSettingsProps) {
  const [settingsGroup, defaultSettings] = ((): [
    JSX.Element?,
    CameraIntegration?,
  ] => {
    switch (value.kind) {
      case 'wristCamera':
        return [
          <WristCameraSettings value={value} onChange={onChange} />,
          DEFAULT_WRIST_CAMERA,
        ];
      default:
        return [];
    }
  })();

  const { setToast } = useToast();

  useEffect(() => {
    if (!doUpdateCameraSettings) {
      return undefined;
    }

    // make sure not to post too often when sliders change
    const timeoutID = setTimeout(async () => {
      try {
        await updateCameraSettings(value);
      } catch (e) {
        setToast({
          kind: 'error',
          message: `Failed to update camera settings (${e?.message})`,
        });
      }
    }, DEBOUNCE_UPDATE_CAMERA_SETTINGS_MS);

    return () => clearTimeout(timeoutID);
  }, [doUpdateCameraSettings, value, setToast]);

  if (!settingsGroup) {
    return null;
  }

  return (
    <fieldset disabled={isDisabled}>
      <legend className="tw-heading-40 tw-pl-16 tw-w-full tw-justify-between">
        <span>Camera settings</span>
        {defaultSettings && (
          <Button
            variant="Flat"
            size={32}
            className="tw-font-regular tw-pr-16"
            onClick={() => onChange(defaultSettings)}
            disabled={isDisabled || isEqual(value, defaultSettings)}
          >
            <Icon kind="arrowUturnLeft" />
            <span>Reset</span>
          </Button>
        )}
      </legend>
      {settingsGroup}
    </fieldset>
  );
}
