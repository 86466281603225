import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';

import { OR_2FG7_GRIP_KINDS_DEFAULT } from '../../constants';
import type { OnRobot2FG7State } from '../../State';
import {
  areOR2FG7GripperPositionsEqual,
  getOR2FG7ActiveWidth,
} from '../../util';

import type { DualValueModeProps } from './types';

interface GripperControlDualValueModeMoreMenuProps extends DualValueModeProps {
  otherRobotGripperState: OnRobot2FG7State | null;
}

export function GripperControlDualValueModeMoreMenu({
  gripperControlState,
  isVizbot,
  otherRobotGripperState,
}: GripperControlDualValueModeMoreMenuProps) {
  const { setGripKind, setTargetGripWidth, command, resetAll, isDisabled } =
    gripperControlState;

  const areGripperPositionsEqual = areOR2FG7GripperPositionsEqual(
    otherRobotGripperState,
    command.targetDiameter,
    command,
  );

  return (
    <NavigationBarMenuButton
      data-testid="2fg7-control-more-menu"
      placement="bottom-start"
      content={
        <Menu>
          <MenuItem
            data-testid="2fg7-control-import-position"
            onClick={() => {
              if (otherRobotGripperState) {
                const gripKind =
                  otherRobotGripperState.gripKind ?? OR_2FG7_GRIP_KINDS_DEFAULT;

                setGripKind(gripKind);

                const gripWidth = getOR2FG7ActiveWidth(otherRobotGripperState);
                setTargetGripWidth(gripWidth);
              }
            }}
            disabled={
              isDisabled || areGripperPositionsEqual || !otherRobotGripperState
            }
            iconKind="arrowDownSquare"
          >
            Import {isVizbot ? 'live Robot' : 'visualizer'} position
          </MenuItem>

          <MenuItem
            data-testid="2fg7-control-reset-all"
            onClick={() => resetAll()}
            disabled={isDisabled}
            iconKind="arrowUturnLeft"
          >
            Reset all
          </MenuItem>
        </Menu>
      }
    />
  );
}
