import {
  Icon,
  NavigationListIcon,
  NavigationListItem,
  NavigationListLabel,
} from '@sb/design-system';
import type { Notification } from '@sb/remote-control/types';

import { getNotificationAgeHumanized, getNotificationMessage } from './utils';

interface NotificationListItemProps {
  notification: Notification.ConvertedResponse;
  isViewed?: boolean;
  onSelect: () => void;
}

export function NotificationListItem({
  notification,
  isViewed,
  onSelect,
}: NotificationListItemProps) {
  return (
    <NavigationListItem onClick={onSelect}>
      <NavigationListIcon className="tw-bg-mango" kind="exclamationCircle" />
      <NavigationListLabel
        subtitleBelow={getNotificationAgeHumanized(notification)}
      >
        {getNotificationMessage(notification)}
      </NavigationListLabel>
      {isViewed && (
        <Icon kind="checkmarkCircle" className="tw-icon-22 tw-text-green" />
      )}
    </NavigationListItem>
  );
}
