import type { Integrations } from '@sb/remote-control/types';

import { MODBUS_TCP_SERVER_NAME_DEFAULT } from '../constants';
import { ModbusTCPServerEquipmentManager } from '../equipment-manager/ModbusTCPServerEquipmentManager';
import { ModbusTCPServerConfiguration } from '../types';

export const modbusTCPServerImplementation: Integrations.IntegrationFrontend<'ModbusTCPServer'> =
  {
    iconKind: 'connectorEthernet',
    // TODO: APPS-4523 Support handling multiple Modbus TCP servers
    canAddAsNewIntegration: (existing) =>
      !existing.some(
        (otherItem) => otherItem.getIntegrationType() === 'NetworkRequest',
      ),
    getIntegrationType: () => 'NetworkRequest',
    getSupportedSteps: () => ['NetworkRequestStep'],
    getDisplayName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getManufacturerName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getShortName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getIntegrationVariables: () => [],
    getDefaultItem: () =>
      ModbusTCPServerConfiguration.parse({ kind: 'ModbusTCPServer' }),
    EquipmentManagerEditForm: ModbusTCPServerEquipmentManager,
  };
