import { useCallback, useState } from 'react';

import type { Robot } from '@sb/remote-control/types';
import {
  Button,
  InputField,
  Modal,
  ModalHeader,
  ModalContent,
} from '@sb/ui/components';
import { useToast } from '@sbrc/hooks';
import { createRobot, updateRobot } from '@sbrc/services';
import { isDuplicateName } from '@sbrc/utils';

import styles from './RobotFormModal.module.css';

interface RobotFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot?: Robot.ConvertedResponse;
}

export default function RobotFormModal({
  isOpen,
  onClose,
  robot,
}: RobotFormModalProps) {
  const { setToast } = useToast();

  const existingRobotName = robot?.name ?? '';

  const [name, setName] = useState<string>(existingRobotName);

  const isValidNameLength = Boolean(name.trim().length);

  const isCurrentName = isDuplicateName(existingRobotName, name);

  const isDisabled = !isValidNameLength || isCurrentName;

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    const changes = { name };

    const request = robot
      ? updateRobot(robot.id, changes)
      : createRobot(changes);

    try {
      setToast({
        kind: 'progress',
        message: `${robot ? 'Editing' : 'Creating'} robot...`,
      });

      await request;

      setToast({
        kind: 'success',
        message: `Robot successfully ${robot ? 'edited' : 'created'}!`,
      });

      onClose();
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  };

  const handleOnClose = useCallback(() => {
    setName(existingRobotName);
    onClose();
  }, [onClose, existingRobotName]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalHeader title={`${robot ? 'Rename' : 'Name'} Robot`} />

      <ModalContent>
        <form className={styles.form} onSubmit={onSubmitForm}>
          <InputField
            value={name}
            onChange={(e) => setName(e.target.value)}
            onClear={() => setName('')}
          >
            Robot Name
          </InputField>

          <div className={styles.buttonGroup}>
            <Button type="submit" disabled={isDisabled}>
              {robot ? 'Save Changes' : 'Create Robot'}
            </Button>

            <Button variant="gray" onClick={handleOnClose}>
              Cancel
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
