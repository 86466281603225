import type { Integrations } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import { OR_SCREWDRIVER_SHORTNAME } from './constants';
import { OnRobotScrewdriverEquipmentManager } from './equipment-manager/OnRobotScrewdriverEquipmentManager';
import { OnRobotScrewdriverConfiguration } from './types/Configuration';
import { OnRobotScrewdriverWidget } from './widget/OnRobotScrewdriverWidget';

export const onRobotScrewdriverImplementation: Integrations.IntegrationFrontend<'OnRobotScrewdriver'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'equipmentScrewdriver',
    getSupportedSteps: () => ['ActuateScrewdriver'],
    getDisplayName: () => {
      return 'OnRobot Screwdriver';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => OR_SCREWDRIVER_SHORTNAME,
    getIntegrationVariables: () => [],
    getDefaultItem: () =>
      OnRobotScrewdriverConfiguration.parse({ kind: 'OnRobotScrewdriver' }),
    Widget: OnRobotScrewdriverWidget,
    EquipmentManagerEditForm: OnRobotScrewdriverEquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobotScrewdriver') {
        return undefined;
      }

      if (!state.isConnected) {
        return 'disconnected';
      }

      if (state.error) {
        return { kind: 'error', message: state.error };
      }

      return state.isBusy ? 'busy' : 'idle';
    },
    getTCPOffsetOptions: (config) => {
      return [config.name];
    },
  };
