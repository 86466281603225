import type { Integrations } from '@sb/remote-control/types';

import { getDynamicBaseDefaults } from '../frontend/getDynamicBaseDefaults';

import { EwellixLiftTLTConfiguration } from './types/Configuration';
import { EwellixLiftTLTWidget } from './widget/EwellixLiftTLTWidget';

export const ewellixLiftTLTImplementation: Integrations.IntegrationFrontend<'EwellixLiftTLT'> =
  {
    ...getDynamicBaseDefaults(),
    iconKind: 'equipmentLift',
    getSupportedSteps: () => [],
    getDisplayName: () => {
      return 'Ewellix Lift TLT';
    },
    getManufacturerName: () => {
      return 'Ewellix';
    },
    getShortName: () => {
      return 'Lift TLT';
    },
    getIntegrationVariables: () => [],
    getDefaultItem: () =>
      EwellixLiftTLTConfiguration.parse({ kind: 'EwellixLiftTLT' }),
    Widget: EwellixLiftTLTWidget,
    getSummaryStatus: (state) => {
      if (state.kind !== 'EwellixLiftTLT') {
        return undefined;
      }

      return state.isMoving ? 'busy' : 'idle';
    },
  };
