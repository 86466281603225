import type { CameraIntegration } from '@sb/integrations/camera/types';

export const RGB_STREAM_URL = '/camera-bot-api/camera/stream/rgb?x-sb-stream';
// `fetch`ing a `multipart/x-mixed-replace` response isn't allowed in Safari, so using a custom mimetype instead
export const RGB_STREAM_MIMETYPE = 'video/x-sb-stream';
export const CAMERA_STATUS_URL = '/camera-bot-api/camera/status';
const SETTINGS_URL = '/camera-bot-api/camera/settings';
const supportedCameraKinds = ['wristCamera'];

export async function updateCameraSettings(
  camera: CameraIntegration,
): Promise<void> {
  if (!supportedCameraKinds.includes(camera.kind)) {
    throw new Error(
      `Settings update failed: unsupported camera kind: ${camera.kind}`,
    );
  }

  const response = await fetch(SETTINGS_URL, {
    method: 'POST',
    body: JSON.stringify(camera),
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    const message = await response.text();
    throw new Error(
      `Settings update failed: Received non-ok response from camera-bot: ${response.status} ${message}`,
    );
  }
}
